import {
    Dialog,
    DialogContent,  
} from '@/components/ui/dialog' 
import { Page, Text, View, Document, StyleSheet, PDFViewer } from '@react-pdf/renderer';
import { UnsettledSecuritiesTotalPosition, ProductTotalPosition } from "@/types/UnsettledSecurities"
import { currencyFormat, numberFormat } from '@/utils/stringFormatter'
import { useEffect, useState } from 'react';
import { calculatePosition1DataDashboard, calculatePosition2DataDashboard, calculatePosition3DataDashboard, calculatePositionTotalDataDashboard } from '@/utils/calculateDataDashboard';

interface ViewPDFProps {
    isOpen: boolean
    onClose: () => void,
    uf: string[]
    distributor: string 
    data: UnsettledSecuritiesTotalPosition[]
    dataProduct: ProductTotalPosition[]
}

type informationProps = {
    overdueAmount: number
    amountDueToday: number
    amountToMature: number
    overdueAmountAgreement: number
    amountDueTodayAgreement: number
    amountToMatureAgreement: number
    overdueAmountpercentageOfRepresentation: number
    amountDueTodaypercentageOfRepresentation: number
    amountToMaturepercentageOfRepresentation: number
    overdueAmountAgreementpercentageOfRepresentation: number
    amountDueTodayAgreementpercentageOfRepresentation: number
    amountToMatureAgreementpercentageOfRepresentation: number
    amountProductTypePA: number
    amountProductTypeAI: number
    amountProductTypePAPercentageOfRepresentation: number
    amountProductTypeAIPercentageOfRepresentation: number
    averageDaysPA: number
    averageDaysAI: number
}

const ViewPDF = ({ isOpen, onClose, data, uf, distributor, dataProduct }: ViewPDFProps) => {

    const [information1, setinformation1] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        amountProductTypePA: 0,
        amountProductTypeAI: 0,
        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeAIPercentageOfRepresentation: 0,
        averageDaysPA: 0,
        averageDaysAI: 0
    }) 
    const [information2, setinformation2] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        amountProductTypePA: 0,
        amountProductTypeAI: 0,
        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeAIPercentageOfRepresentation: 0,
        averageDaysPA: 0,
        averageDaysAI: 0
    }) 
    const [information3, setinformation3] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        amountProductTypePA: 0,
        amountProductTypeAI: 0,
        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeAIPercentageOfRepresentation: 0,
        averageDaysPA: 0,
        averageDaysAI: 0
    }) 
    const [informationTotal, setinformationTotal] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        amountProductTypePA: 0,
        amountProductTypeAI: 0,
        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeAIPercentageOfRepresentation: 0,
        averageDaysPA: 0,
        averageDaysAI: 0
    }) 

    useEffect(() => { 
        setinformation1(calculatePosition1DataDashboard({data, distributor, uf, dataProduct}))
        setinformation2(calculatePosition2DataDashboard({data, distributor, uf, dataProduct}))
        setinformation3(calculatePosition3DataDashboard({data, distributor, uf, dataProduct}))
        setinformationTotal(calculatePositionTotalDataDashboard({data, distributor, uf, dataProduct}))
      
    },[data, uf, distributor]) 

    const styles = StyleSheet.create({
        row: { display: 'flex', flexDirection: 'row' },
        nav: {
          width: '100%',
          backgroundColor: '#ececec',
          padding: 4,
          marginTop: 16,
          marginBottom: 8,
        },
        title: {
          fontSize: 10,
          fontWeight: 'bold',
        },
        subTitle: {
          fontSize: 8,
          color: '#2b2b2b',
          width: '250px',
          marginTop: 4,
        },
        containerItem: {
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          paddingHorizontal: 4,
        },
        containerInsumos: {
          display: 'flex',
          flexDirection: 'row',
        },
        cabecalhoInsumos: { 
          paddingBottom: 4,
          marginBottom: 4,
        },
        itemInsumos: {
          display: 'flex',
          flexDirection: 'row',
          paddingVertical: 2,
          borderBottom: '0.5px solid #dadada',
        },
        column: {
          flex: 1,
          paddingHorizontal: 4,
        },
        column3: {
            flex: 0.5,
            paddingHorizontal: 4,
            textAlign: 'right'
          },
      });

    const PDF = ( {dataInformation1, dataInformation2, dataInformation3, dataInformationTotal} ) => { 
        return (
            <Document>
                <Page size="A4" style={{ padding: 10 }}> 
                    <View>
                        <View style={styles.nav}>
                            <Text style={{ fontSize: 14 }}>Posição 1</Text>
                        </View>
                        <View style={styles.containerItem}> 
                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Carteira</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformation1.overdueAmount)}  ({numberFormat(dataInformation1.overdueAmountpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformation1.amountDueToday)} ({numberFormat(dataInformation1.amountDueTodaypercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformation1.amountToMature)} ({numberFormat(dataInformation1.amountToMaturepercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Carteira: {currencyFormat(dataInformation1.totalInPortifolio)} ({numberFormat(dataInformation1.totalInPortifolioOfRepresentation)}%)
                                    </Text> 
                                </View>  

                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Acordo</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformation1.overdueAmountAgreement)}  ({numberFormat(dataInformation1.overdueAmountAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformation1.amountDueTodayAgreement)} ({numberFormat(dataInformation1.amountDueTodayAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformation1.amountToMatureAgreement)} ({numberFormat(dataInformation1.amountToMatureAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Acordo: {currencyFormat(dataInformation1.fullyInAgreement)} ({numberFormat(dataInformation1.fullyInAgreementOfRepresentation)}%)
                                    </Text> 
                                </View>  
                            </View>
                            
                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Produtos</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Produto Acabado: {currencyFormat(dataInformation1.amountProductTypePA)} ({numberFormat(dataInformation1.amountProductTypePAPercentageOfRepresentation)}%) | {numberFormat(dataInformation1.averageDaysPA)} Média Dias
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Demais: {currencyFormat(dataInformation1.amountProductTypeDEMAIS)} ({numberFormat(dataInformation1.amountProductTypeDEMAISPercentageOfRepresentation)}%) | {numberFormat(dataInformation1.averageDaysDEMAIS)} Média Dias
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total Produtos: {currencyFormat(dataInformation1.totalProducts)} ({numberFormat(dataInformation1.totalProductOfRepresentation)}%) | {numberFormat(dataInformation1.averageDaysTotal)} Média Dias
                                    </Text> 
                                </View> 
                            </View>
                        </View>

                        <View style={styles.nav}>
                            <Text style={{ fontSize: 14 }}>Posição 2</Text>
                        </View>
                        <View style={styles.containerItem}> 
                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Carteira</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformation2.overdueAmount)}  ({numberFormat(dataInformation2.overdueAmountpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformation2.amountDueToday)} ({numberFormat(dataInformation2.amountDueTodaypercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformation2.amountToMature)} ({numberFormat(dataInformation2.amountToMaturepercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Carteira: {currencyFormat(dataInformation2.totalInPortifolio)} ({numberFormat(dataInformation2.totalInPortifolioOfRepresentation)}%)
                                    </Text> 
                                </View>  

                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Acordo</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformation2.overdueAmountAgreement)}  ({numberFormat(dataInformation2.overdueAmountAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformation2.amountDueTodayAgreement)} ({numberFormat(dataInformation2.amountDueTodayAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformation2.amountToMatureAgreement)} ({numberFormat(dataInformation2.amountToMatureAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Acordo: {currencyFormat(dataInformation2.fullyInAgreement)} ({numberFormat(dataInformation2.fullyInAgreementOfRepresentation)}%)
                                    </Text> 
                                </View>  
 
                            </View>

                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Produtos</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Produto Acabado: {currencyFormat(dataInformation2.amountProductTypePA)} ({numberFormat(dataInformation2.amountProductTypePAPercentageOfRepresentation)}%) | {numberFormat(dataInformation2.averageDaysPA)} Média Dias
                                    </Text>  
                                    <Text style={[styles.subTitle]}>
                                        Total Produtos: {currencyFormat(dataInformation2.amountProductTypePA)} ({numberFormat(dataInformation2.amountProductTypePAPercentageOfRepresentation)}%) | {numberFormat(dataInformation2.averageDaysPA)} Média Dias
                                    </Text> 
                                </View> 
                            </View>
                        </View>
 
                        <View style={styles.nav}>
                            <Text style={{ fontSize: 14 }}>Posição 3</Text>
                        </View>
                        <View style={styles.containerItem}> 
                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Carteira</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformation3.overdueAmount)}  ({numberFormat(dataInformation3.overdueAmountpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformation3.amountDueToday)} ({numberFormat(dataInformation3.amountDueTodaypercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformation3.amountToMature)} ({numberFormat(dataInformation3.amountToMaturepercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Carteira: {currencyFormat(dataInformation3.totalInPortifolio)} ({numberFormat(dataInformation3.totalInPortifolioOfRepresentation)}%)
                                    </Text> 
                                </View>  

                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Acordo</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformation3.overdueAmountAgreement)}  ({numberFormat(dataInformation3.overdueAmountAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformation3.amountDueTodayAgreement)} ({numberFormat(dataInformation3.amountDueTodayAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformation3.amountToMatureAgreement)} ({numberFormat(dataInformation3.amountToMatureAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Acordo: {currencyFormat(dataInformation3.fullyInAgreement)} ({numberFormat(dataInformation3.fullyInAgreementOfRepresentation)}%)
                                    </Text> 
                                </View>   
                            </View>

                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Produtos</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Produto Acabado: {currencyFormat(dataInformation3.amountProductTypePA)} ({numberFormat(dataInformation3.amountProductTypePAPercentageOfRepresentation)}%) | {numberFormat(dataInformation3.averageDaysPA)} Média Dias
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total Produtos: {currencyFormat(dataInformation3.amountProductTypePA)} ({numberFormat(dataInformation3.amountProductTypePAPercentageOfRepresentation)}%) | {numberFormat(dataInformation3.averageDaysPA)} Média Dias
                                    </Text> 
                                </View>  
                            </View>
                        </View>
    

                        <View style={styles.nav}>
                            <Text style={{ fontSize: 14 }}>Posição Total</Text>
                        </View>
                        <View style={styles.containerItem}> 
                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Carteira</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformationTotal.overdueAmount)}  ({numberFormat(dataInformationTotal.overdueAmountpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformationTotal.amountDueToday)} ({numberFormat(dataInformationTotal.amountDueTodaypercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformationTotal.amountToMature)} ({numberFormat(dataInformationTotal.amountToMaturepercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Carteira: {currencyFormat(dataInformationTotal.totalInPortifolio)} ({numberFormat(dataInformationTotal.totalInPortifolioOfRepresentation)}%)
                                    </Text> 
                                </View>  

                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Em Acordo</Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido: {currencyFormat(dataInformationTotal.overdueAmountAgreement)}  ({numberFormat(dataInformationTotal.overdueAmountAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor Vencido Hoje: {currencyFormat(dataInformationTotal.amountDueTodayAgreement)} ({numberFormat(dataInformationTotal.amountDueTodayAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Valor à Vencer: {currencyFormat(dataInformationTotal.amountToMatureAgreement)} ({numberFormat(dataInformationTotal.amountToMatureAgreementpercentageOfRepresentation)}%)
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total em Acordo: {currencyFormat(dataInformationTotal.fullyInAgreement)} ({numberFormat(dataInformationTotal.fullyInAgreementOfRepresentation)}%)
                                    </Text> 
                                </View>   
                            </View>

                            <View style={styles.containerInsumos}>
                                <View style={styles.cabecalhoInsumos}>
                                    <Text style={[styles.title]}>Produtos</Text>  
                                    <Text style={[styles.subTitle]}>
                                        Produto Acabado: {currencyFormat(dataInformationTotal.amountProductTypePA)} ({numberFormat(dataInformationTotal.amountProductTypePAPercentageOfRepresentation)}%) | {numberFormat(dataInformationTotal.averageDaysPA)} Média Dias
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Demais: {currencyFormat(dataInformationTotal.amountProductTypeDEMAIS)} ({numberFormat(dataInformationTotal.amountProductTypeDEMAISPercentageOfRepresentation)}%) | {numberFormat(dataInformationTotal.averageDaysDEMAIS)} Média Dias
                                    </Text> 
                                    <Text style={[styles.subTitle]}>
                                        Total Produtos: {currencyFormat(dataInformationTotal.totalProducts)} ({numberFormat(dataInformationTotal.totalProductOfRepresentation)}%) | {numberFormat(dataInformationTotal.averageDaysTotal)} Média Dias
                                    </Text> 
                                </View>  
                            </View>
                        </View>
 
                    </View>
                </Page>
            </Document>  

        )
    }
 
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[1000px] overflow-auto h-4/5'>   
                <PDFViewer className="w-full h-full pt-4">
                    <PDF dataInformation1={information1} dataInformation2={information2} dataInformation3={information3} dataInformationTotal={informationTotal}/>
                </PDFViewer>
            </DialogContent>
        </Dialog>
    )
}

export default ViewPDF
