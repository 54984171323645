import { Table } from '@tanstack/react-table'
import { UnsettledSecuritiesTable } from '.'
import { utils, writeFile } from 'xlsx'
import { UnsettledSecuritiesPosition1 } from '@/types/UnsettledSecurities'
import { getDateFromId } from '@/utils/date'
 
export const usePosition1Spreadsheets = () => {
    
    const getTableRows = (table: Table<UnsettledSecuritiesTable>): Partial<UnsettledSecuritiesPosition1>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any>

            obj['Revenda'] = row.original.NM_REVENDA
            obj['Empresa'] = row.original.ABREVIATURA_EMPRESA
            obj['Cod. Cliente'] = row.original.NK_CLIENTE
            obj['Nome Fantasia'] = row.original.DS_NOME_FANTASIA
            obj['UF'] = row.original.DD_ESTADO
            obj['Num. Doc'] = row.original.DD_NUMERO_DOCUMENTO
            obj['Data Emissão'] = getDateFromId(row.original.SK_EMISSAO)
            obj['Data Vencimento'] = getDateFromId(Number(row.original.SK_VENCIMENTO))
            obj['Data Vencimento Real'] = getDateFromId(Number(row.original.SK_VENCIMENTO_REAL))
            obj['Data Baixa'] = row.original.SK_BAIXA ? getDateFromId(Number(row.original.SK_BAIXA)) : ''
            obj['Dias Juros'] = Number(row.original.DD_DIAS_JUROS)
            obj['Valor N.F.'] = Number(row.original.VL_NF)
            obj['Valor a Vencer'] = Number(row.original.VL_A_VENCER)
            obj['Valor Vencido'] = Number(row.original.VL_VENCIDO)
            obj['Valor Vencido + A Vencer'] = Number(row.original.VL_VENCIDO_E_A_VENCER)
            obj['Juros Pelo Prazo'] = Number(row.original.VL_JUROS_PELO_PRAZO)
            obj['Valor Multa'] = Number(row.original.VL_MULTA)
            obj['Valor Total com Juros'] = Number(row.original.VL_TOTAL_COM_JUROS)
            obj['Num. Bordero'] = row.original.DD_NUMERO_BORDERO
            obj['Portador'] = row.original.DD_PORTADOR
            obj['Data Bordero'] = row.original.SK_TEMPO_BORDERO ? getDateFromId(Number(row.original.SK_TEMPO_BORDERO)) : ''
            obj['Tipo N.F'] = row.original.DD_TIPO
            obj['Historico'] = row.original.DS_HISTORICO
            obj['Cod. Produto'] = row.original.NK_PRODUTO
            obj['Produto'] = row.original.DS_PRODUTO
            obj['Tipo Produto'] = row.original.DD_TIPO_PRODUTO
            obj['U.M.'] = row.original.DD_UNIDADE_MEDIDA
            obj['NCM'] = row.original.DD_NCM
            obj['Cod. Condição Pag.'] = row.original.DD_CODIGO_CONDICAO_PAGAMENTO
            obj['Condição Pagamento'] = row.original.DD_NM_CONDICAO_PAGAMENTO
            obj['Intercompany?'] = row.original.DD_INTERCOMPANY
            obj['CNPJ'] = row.original.DD_CNPJ
            obj['Parcela'] = row.original.DD_PARCELA
            obj['Distribuidor'] = row.original.NM_PROPRIETARIO
            obj['Prefixo'] = row.original.DD_PREFIXO
            obj['ID Carga'] = row.original.DD_IDCARGA
            obj['Valor Multa Baixada'] = Number(row.original.VL_MULTA_BAIXADA)
            obj['Valor Juros Baixado'] = Number(row.original.VL_JUROS_BAIXADO)
            obj['Classificação'] = row.original.DD_CLASSIFICACAO

            return obj
        })

    const onDownload = (tableInstance: Table<UnsettledSecuritiesTable>) => {
         
        const rows = getTableRows(tableInstance) 
        const colluns = Object.keys(rows[0]).length;

        const formatDate = 'dd/mm/yyyy'
        const formatMoneyAccounting = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'

        const worksheet = utils.aoa_to_sheet([[]]);
         
        worksheet['K1'] =  { t: 's', v: 'Totais: ' }
        worksheet['L1'] =  { t: 'n', f: `SUBTOTAL(9, L3:L${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['M1'] =  { t: 'n', f: `SUBTOTAL(9, M3:M${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['N1'] =  { t: 'n', f: `SUBTOTAL(9, N3:N${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['O1'] =  { t: 'n', f: `SUBTOTAL(9, O3:O${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['P1'] =  { t: 'n', f: `SUBTOTAL(9, P3:P${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['Q1'] =  { t: 'n', f: `SUBTOTAL(9, Q3:Q${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['R1'] =  { t: 'n', f: `SUBTOTAL(9, R3:R${rows.length + 3})`, z: formatMoneyAccounting }
        
        utils.sheet_add_json(worksheet, rows, {
            origin: { r: 1, c: 0 } 
        });

        for (let i = 2; i <= rows.length + 2; i++) { 
            
            worksheet[`G${i}`].z = formatDate
            worksheet[`H${i}`].z = formatDate
            worksheet[`I${i}`].z = formatDate
            worksheet[`J${i}`].z = formatDate
            worksheet[`U${i}`].z = formatDate
            
            worksheet[`L${i}`].z = formatMoneyAccounting
            worksheet[`M${i}`].z = formatMoneyAccounting
            worksheet[`N${i}`].z = formatMoneyAccounting
            worksheet[`O${i}`].z = formatMoneyAccounting
            worksheet[`P${i}`].z = formatMoneyAccounting
            worksheet[`Q${i}`].z = formatMoneyAccounting
            worksheet[`R${i}`].z = formatMoneyAccounting
            worksheet[`AK${i}`].z = formatMoneyAccounting
            worksheet[`AL${i}`].z = formatMoneyAccounting
        }

        worksheet["!cols"] =  Array(colluns).fill({ wch: 20 });

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Titulos')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Titulos_Posicao1_${currentDateFormated}.xlsx`, {
            compression: true,
            cellStyles: true
        })
    }

    return {
        onDownload,
    }
}
