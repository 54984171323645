import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog'
import { useToast } from '@/components/ui/use-toast'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isAxiosError } from '@/api/business'
import { createResale } from '@/api/business/resale'
import { ResaleKeys } from '@/queries/useResaleQuery'
import Button from '@/components/Button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
    
} from '@/components/ui/form'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod' 

interface AddDialogProps {
    isOpen: boolean
    onClose: () => void
}

const AddDialog = ({ isOpen, onClose }: AddDialogProps) => {
    const queryClient = useQueryClient()
    const { toast } = useToast() 

    const schema = z.object({
        DS_ROTA: z.string().min(1, {
            message: 'O campo Nome da Rota é obrigatório',
        }),
        DD_ROTA: z.string(),
        DD_TIPO: z.string(),
        NM_PROPRIETARIO: z.string(),
        DD_CLIENTE: z.string(),
        DD_LOJA: z.string(),
        NM_CLIENTE: z.string(),
        DS_REVENDA_GRADE: z.string(),
        NM_REVENDA: z.string(),
        NM_MUNICIPIO: z.string(),
        NM_ENDERECO: z.string(),
        NM_BAIRRO: z.string(),
        DD_CEP: z.string(),
        DD_ESTADO: z.string(),
        DD_CNPJ: z.string(),
        DD_APARECER_LIMITE: z.string(),
        DD_PRAZO: z.string(),
        DD_VERSAO: z.string(),
        DD_LIMITE: z.string(),
        DD_TIPO_GARANTIA: z.string(),
        DD_VALOR: z.string(),
        DS_OBSERVACAO1: z.string(),
        DS_OBSERVACAO2: z.string(),
        DD_CADASTRO_LIMITE: z.string(),
        DD_EXIBIR_DASHBOARD: z.number()
    })

    const form = useForm<z.infer<typeof schema>>({
        resolver: zodResolver(schema),
        defaultValues: {
            DS_ROTA: '',
            DD_ROTA: '',
            DD_TIPO: '',
            NM_PROPRIETARIO: '',
            DD_CLIENTE: '',
            DD_LOJA: '',
            NM_CLIENTE: '',
            DS_REVENDA_GRADE: '',
            NM_REVENDA: '',
            NM_MUNICIPIO: '',
            NM_ENDERECO: '',
            NM_BAIRRO: '',
            DD_CEP: '',
            DD_ESTADO: '',
            DD_CNPJ: '',
            DD_APARECER_LIMITE: '',
            DD_PRAZO: '',
            DD_VERSAO: '',
            DD_LIMITE: '',
            DD_TIPO_GARANTIA: '',
            DD_VALOR: '',
            DS_OBSERVACAO1: '',
            DS_OBSERVACAO2: '',
            DD_CADASTRO_LIMITE: '',
            DD_EXIBIR_DASHBOARD: 1
        },
    })

    const { mutate, isPending: mutateLoading } = useMutation({
        mutationFn: createResale,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ResaleKeys.lists() })
            onClose()
            form.reset()
            toast({
                title: 'Revenda criado com sucesso',
            })
        },
        onError: (err) => {
            let errorMessage =
                'Não foi possível criar a revenda. Tente novamente mais tarde.'

            if (isAxiosError(err)) {
                errorMessage = err.response?.data.message || errorMessage
            }

            toast({
                title: 'Erro ao criar revenda',
                description: errorMessage,
                variant: 'destructive',
            })
        },
    })

    const onSubmit = (data: z.infer<typeof schema>) => {
        mutate({
            ...data
        })
    }

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className='max-w-[1000px] overflow-auto h-3/4'>
                <DialogHeader className='px-2'>
                    <DialogTitle>Adicionar Revenda</DialogTitle>
                </DialogHeader>
                <Form {...form}>
                    <form
                        onSubmit={form.handleSubmit(onSubmit)}
                        className="space-y-2 px-2 overflow-hidden overflow-y-auto"
                    >
                        <div className='grid grid-cols-[3fr,1fr,1fr,3fr] gap-4'>
                            <FormField
                                name="DS_ROTA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome rota</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_ROTA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Cod. Rota</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_TIPO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Tipo</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_PROPRIETARIO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Distribuidor</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid grid-cols-[1fr,1fr,5fr] gap-4'>
                            <FormField
                                name="DD_CLIENTE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Cod. Cliente</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_LOJA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Loja Cliente</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_CLIENTE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome Cliente</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div>
                        
                        <div className='grid grid-cols-[1fr,1fr] gap-4'> 
                            <FormField
                                name="DS_REVENDA_GRADE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Revenda na Grade</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_REVENDA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Nome Revenda</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>

                        <div className='grid grid-cols-[1fr,2fr] gap-4'> 
                            <FormField
                                name="NM_MUNICIPIO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Municipio</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="NM_ENDERECO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Endereço</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div>
                        
                        <div className='grid grid-cols-[6fr,2fr,1fr] gap-4'> 
                            <FormField
                                name="NM_BAIRRO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Bairro</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_CEP"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>CEP</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_ESTADO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>UF</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <div className='grid grid-cols-[2fr,1fr,1fr,1fr,1fr] gap-4'>
                            <FormField
                                name="DD_CNPJ"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>CNPJ</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_APARECER_LIMITE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Aparecer limite</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_PRAZO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Prazo</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_VERSAO"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Versão</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DD_CADASTRO_LIMITE"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Cadastro no Limite</FormLabel>
                                        <Select onValueChange={(value) => field.onChange(value)} defaultValue={field.value.toString()}>
                                            <FormControl>
                                                <SelectTrigger>
                                                <SelectValue placeholder="" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value='NÃO'>NÃO</SelectItem>
                                                <SelectItem value="SIM">SIM</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                        </div>
                        <div className='grid grid-cols-[1fr,1fr,2fr,1fr] gap-4'>
                            <FormField
                                name="DD_LIMITE"
                                control={form.control} 
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Limite</FormLabel>
                                        <FormControl>
                                            <Input {...field} type='number'/>
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                            <FormField
                                name="DD_TIPO_GARANTIA"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Tipo Garantia</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                            <FormField
                                name="DD_VALOR"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Valor</FormLabel>
                                        <FormControl>
                                            <Input {...field} />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            /> 
                            <FormField
                                control={form.control}
                                name="DD_EXIBIR_DASHBOARD"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Exibir no Dashboard</FormLabel> 
                                        <Select onValueChange={(value) => field.onChange(value == 'SIM' ? 1 : 0)} defaultValue={field.value ? 'SIM' : 'NÃO'}>
                                            <FormControl>
                                                <SelectTrigger>
                                                <SelectValue placeholder="" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                <SelectItem value='NÃO'>NÃO</SelectItem>
                                                <SelectItem value="SIM">SIM</SelectItem>
                                            </SelectContent>
                                        </Select>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div> 
                        <div className='grid grid-cols-[1fr,1fr] gap-4'>
                            <FormField
                                name="DS_OBSERVACAO1"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>1º Observação</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} /> 
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                            <FormField
                                name="DS_OBSERVACAO2"
                                control={form.control}
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>2º Observação</FormLabel>
                                        <FormControl>
                                            <Textarea {...field} /> 
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />
                        </div>
                        <DialogFooter>
                            <Button
                                type="button"
                                onClick={onClose}
                                variant="ghost"
                            >
                                Cancelar
                            </Button>
                            <Button type="submit" isLoading={mutateLoading}>
                                Confirmar
                            </Button>
                        </DialogFooter> 
                    </form>
                </Form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDialog
