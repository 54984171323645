import { Table } from '@tanstack/react-table'
import { BalanceSammaryTable } from '.'
import { utils, writeFile } from 'xlsx'
import { Movimentation } from '@/types/BankStatement'
import { getDateFromId } from '@/utils/date'
  
export const useBalanceSammarySpreadsheets = () => {
    const getTableRows = (table: Table<BalanceSammaryTable>): Partial<Movimentation>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any> 
            
            obj['Data'] = getDateFromId(Number(row.original.DATA))
            obj['Rota'] = row.original.DD_ROTA 
            obj['Descrição Rota'] = row.original.DS_ROTA 
            obj['Distribuidor'] = row.original.NM_PROPRIETARIO 
            obj['Histórico'] = row.original.DS_HISTORICO
            obj['Valor Entrada'] = Number(row.original.ENTRADA)
            obj['Valor Saída'] = Number(row.original.SAIDA)
            obj['Valor Saldo'] = Number(row.original.SALDO)

            return obj
        })

    const onDownload = (tableInstance: Table<BalanceSammaryTable>) => {
         
        const rows = getTableRows(tableInstance)
        const colluns = Object.keys(rows[0]).length;

        const formatDate = 'dd/mm/yyyy'
        const formatMoneyAccounting = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'

        const worksheet = utils.aoa_to_sheet([[]]);
         
        worksheet['E1'] =  { t: 's', v: 'Totais: ' }
        worksheet['F1'] =  { t: 'n', f: `SUBTOTAL(9, F3:F${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['G1'] =  { t: 'n', f: `SUBTOTAL(9, G3:G${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['H1'] =  { t: 'n', f: `SUBTOTAL(9, H3:H${rows.length + 3})`, z: formatMoneyAccounting } 
        
        utils.sheet_add_json(worksheet, rows, {
            origin: { r: 1, c: 0 } 
        });

        for (let i = 3; i <= rows.length + 2; i++) { 
            
            worksheet[`A${i}`].z = formatDate 

            worksheet[`F${i}`].z = formatMoneyAccounting
            worksheet[`G${i}`].z = formatMoneyAccounting
            worksheet[`H${i}`].z = formatMoneyAccounting  
        }

        worksheet["!cols"] =  Array(colluns).fill({ wch: 20 });

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Resumo do Saldo')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `Extrato_Revenda_Resumo_Saldo_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
