import { UnsettledSecuritiesTotalPosition, ProductTotalPosition } from "@/types/UnsettledSecurities"
import { useEffect, useState } from "react"
import PositionTotalText from "./componentes/text"
import { calculatePositionTotalDataDashboard } from "@/utils/calculateDataDashboard"

interface PositionProps {
    data: UnsettledSecuritiesTotalPosition[] 
    dataProduct: ProductTotalPosition[]
    uf: string[]
    distributor: string  
} 

type informationProps = {
    overdueAmount: number
    amountDueToday: number
    amountToMature: number
    totalInPortifolio: number

    overdueAmountAgreement: number
    amountDueTodayAgreement: number
    amountToMatureAgreement: number
    fullyInAgreement: number

    overdueAmountpercentageOfRepresentation: number
    amountDueTodaypercentageOfRepresentation: number
    amountToMaturepercentageOfRepresentation: number
    totalInPortifolioOfRepresentation: number

    overdueAmountAgreementpercentageOfRepresentation: number
    amountDueTodayAgreementpercentageOfRepresentation: number
    amountToMatureAgreementpercentageOfRepresentation: number
    fullyInAgreementOfRepresentation: number

    amountProductTypePA: number
    amountProductTypeDEMAIS: number 
    totalProducts: number

    amountProductTypePAPercentageOfRepresentation: number
    amountProductTypeDEMAISPercentageOfRepresentation: number
    totalProductOfRepresentation: number

    averageDaysPA: number
    averageDaysDEMAIS: number
    averageDaysTotal: number
}

 const PositionTotalDashboard = ({data, dataProduct, uf, distributor} : PositionProps) => {  
    const [information, setinformation] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        totalInPortifolio: 0,

        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        fullyInAgreement: 0,

        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        totalInPortifolioOfRepresentation: 0,

        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        fullyInAgreementOfRepresentation: 0,

        amountProductTypePA: 0,
        amountProductTypeDEMAIS: 0,
        totalProducts: 0,

        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeDEMAISPercentageOfRepresentation: 0,
        totalProductOfRepresentation: 0,

        averageDaysPA: 0,
        averageDaysDEMAIS: 0,
        averageDaysTotal: 0
     }) 
     
     useEffect(() => {  
        setinformation(calculatePositionTotalDataDashboard({data, distributor, uf, dataProduct}) ) 
     },[data, uf, distributor])

    return (
        <>
            {information && (
                <PositionTotalText information={information} distributor={distributor} uf={uf} />
            )} 
        </> 
    )
}

export default PositionTotalDashboard
