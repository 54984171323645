import { useUnsettledSecuritiesPosition1Query } from "@/queries/useUnsettledSecuritiesPosition1Query"
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb'
import { Link } from 'react-router-dom' 
import { useCallback, useEffect, useMemo, useState } from 'react'
import Table from '@/components/Table'
import { createColumnHelper } from '@tanstack/react-table'
import { TableData } from '@/components/Table/type'
import { UnsettledSecuritiesPosition1 } from "@/types/UnsettledSecurities"
import DefaultColumn from '@/components/Table/components/DefaultColumn' 
import { getDateFromId } from '@/utils/date'
import { format } from 'date-fns' 
import { currencyFormat } from "@/utils/stringFormatter" 
import { TypographyH4 } from '@/components/ui/typography'
import CardSummary from "../components/cards"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX, RefreshCcw } from "lucide-react"
import { usePosition1Spreadsheets } from "./usePosition1Spreadsheets"
import Button from "@/components/Button"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { useTable } from "@/hooks/useTable"

export type UnsettledSecuritiesTable = TableData<UnsettledSecuritiesPosition1>

type sumarrProps = {
    title: string
    subtitle?: string
    value: string
    observation?: string
}
const columnHelper = createColumnHelper<UnsettledSecuritiesPosition1>()

const Position1 = () => {
    const {
        table, 
        setTable,
    } = useTable<UnsettledSecuritiesPosition1>('')
    const [selectClassification,setSelectClassification] = useState<string>()
    const [classification,setClassification] = useState<string[]>([])
    const [dataTableCurrent, setDataTableCurrent] = useState<UnsettledSecuritiesPosition1[]>()
    const [summary, setSumarry] = useState<sumarrProps[]>()
    const { data, isFetching, isPending, refetch } = useUnsettledSecuritiesPosition1Query()
    const { onDownload } = usePosition1Spreadsheets()

    useEffect(() => {
        if (dataTableCurrent) {
            const summary = generateCardSummary(dataTableCurrent);
            setSumarry(summary);
        }
    }, [dataTableCurrent])

    const generateCardSummary = useCallback((table) => { 
        const amoutVL_NF = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_NF);
        }, 0);

        const amoutVL_A_VENCER = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_A_VENCER);
        }, 0);
        
        const amoutVL_VENCIDO = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO);
        }, 0);

        const amoutVL_VENCIDO_E_A_VENCER = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER);
        }, 0);

        const amoutVL_JUROS_PELO_PRAZO = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_JUROS_PELO_PRAZO);
        }, 0);
        
        const amoutVL_MULTA = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_MULTA);
        }, 0);

        const amoutVL_TOTAL_COM_JUROS = table.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS);
        }, 0); 

        return [
        {
            title: "Valor N.F.", 
            value: currencyFormat(amoutVL_NF),
        },{
            title: "Valor a Vencer", 
            value: currencyFormat(amoutVL_A_VENCER),
        },{
            title: "Valor Vencido", 
            value: currencyFormat(amoutVL_VENCIDO)
        },{
            title: "Valor Vencido + A Vencer", 
            value: currencyFormat(amoutVL_VENCIDO_E_A_VENCER)
        },{
            title: "Juros Pelo Prazo", 
            value: currencyFormat(amoutVL_JUROS_PELO_PRAZO)
        },{
            title: "Valor Multa", 
            value: currencyFormat(amoutVL_MULTA)
        },{
            title: "Valor Total Com Juros", 
            value: currencyFormat(amoutVL_TOTAL_COM_JUROS)
        },
    ]
    }, [])
     
    const memoData = useMemo(() => { 
        return data?.filter(data => data.DD_CLASSIFICACAO == selectClassification) || []
        
    } , [data, selectClassification])

    useEffect(() => {
        if(data){
            const valoresUnicos = data ? Array.from(new Set(data.map(item => item.DD_CLASSIFICACAO))) : [] 
            setClassification(valoresUnicos)
            setSelectClassification(valoresUnicos[0])
        }
    },[data])

    const memoColumns = useMemo(
        () => [
            columnHelper.accessor('NM_REVENDA', {
                id: 'NM_REVENDA',
                header: 'Revenda',
                size: 140,
            }),
            columnHelper.accessor('ABREVIATURA_EMPRESA', {
                id: 'ABREVIATURA_EMPRESA',
                header: 'Empresa',
                size: 140,
            }),
            columnHelper.accessor('NK_CLIENTE', {
                id: 'NK_CLIENTE',
                header: 'Cod. Cliente',
                size: 120,
            }), 
            columnHelper.accessor('DS_NOME_FANTASIA', {
                id: 'DS_NOME_FANTASIA',
                header: 'Nome Fantasia',
                size: 250,
            }), 
            columnHelper.accessor('DD_ESTADO', {
                id: 'DD_ESTADO',
                header: 'UF',
                size: 80,
            }), 
            columnHelper.accessor('DD_NUMERO_DOCUMENTO', {
                id: 'DD_NUMERO_DOCUMENTO',
                header: 'Num. Doc',
                size: 140,
            }), 
            columnHelper.accessor(({ SK_EMISSAO }) =>
                SK_EMISSAO
                    ? format(getDateFromId(Number(SK_EMISSAO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_EMISSAO',
                header: 'Data Emissão',
                size: 130,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO }) =>
                SK_VENCIMENTO
                    ? format(getDateFromId(Number(SK_VENCIMENTO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO',
                header: 'Data Vencimento',
                size: 145,
            }), 
            columnHelper.accessor(({ SK_VENCIMENTO_REAL }) =>
                SK_VENCIMENTO_REAL
                    ? format(getDateFromId(Number(SK_VENCIMENTO_REAL)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_VENCIMENTO_REAL',
                header: 'Data Vencimento Real',
                size: 175,
            }), 
            columnHelper.accessor(({ SK_BAIXA }) =>
                SK_BAIXA
                    ? format(getDateFromId(Number(SK_BAIXA)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_BAIXA',
                header: 'Data Baixa',
                size: 120,
            }), 
            columnHelper.accessor('DD_DIAS_JUROS', {
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'DD_DIAS_JUROS',
                header: 'Dias Juros',
                size: 110,
            }), 
            columnHelper.accessor(({ VL_NF }) =>
                VL_NF
                    ? currencyFormat(Number(VL_NF))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_NF',
                header: 'Valor N.F.', 
                size: 140,
            }),  
            columnHelper.accessor(({ VL_A_VENCER }) =>
                VL_A_VENCER
                    ? currencyFormat(Number(VL_A_VENCER))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_A_VENCER',
                header: 'Valor a Vencer',
                size: 140,
            }), 
            columnHelper.accessor(({ VL_VENCIDO }) =>
                VL_VENCIDO
                    ? currencyFormat(Number(VL_VENCIDO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO',
                header: 'Valor Vencido',
                size: 140,
            }), 
            columnHelper.accessor(({ VL_VENCIDO_E_A_VENCER }) =>
                VL_VENCIDO_E_A_VENCER
                    ? currencyFormat(Number(VL_VENCIDO_E_A_VENCER))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_VENCIDO_E_A_VENCER',
                header: 'Valor Vencido + A Vencer',
                size: 140,
            }), 
            columnHelper.accessor(({ VL_JUROS_PELO_PRAZO }) =>
                VL_JUROS_PELO_PRAZO
                    ? currencyFormat(Number(VL_JUROS_PELO_PRAZO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_PELO_PRAZO',
                header: 'Juros Pelo Prazo',
                size: 140,
            }), 
            columnHelper.accessor(({ VL_MULTA }) =>
                VL_MULTA
                    ? currencyFormat(Number(VL_MULTA))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA',
                header: 'Valor Multa',
                size: 140,
            }), 
            columnHelper.accessor(({ VL_TOTAL_COM_JUROS }) =>
                VL_TOTAL_COM_JUROS
                    ? currencyFormat(Number(VL_TOTAL_COM_JUROS))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_TOTAL_COM_JUROS',
                header: 'Valor Total com Juros',
                size: 170,
            }),  
            columnHelper.accessor('DD_NUMERO_BORDERO', {
                id: 'DD_NUMERO_BORDERO',
                header: 'Num. Bordero',
                size: 130,
            }), 
            columnHelper.accessor('DD_PORTADOR', {
                id: 'DD_PORTADOR',
                header: 'Portador',
                size: 120,
            }), 
            columnHelper.accessor(({ SK_TEMPO_BORDERO }) =>
                SK_TEMPO_BORDERO
                    ? format(getDateFromId(Number(SK_TEMPO_BORDERO)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'SK_TEMPO_BORDERO',
                header: 'Data Bordero',
                size: 130,
            }),  
            columnHelper.accessor('DD_TIPO', {
                id: 'DD_TIPO',
                header: 'Tipo N.F',
                size: 100,
            }), 
            columnHelper.accessor('DS_HISTORICO', {
                id: 'DS_HISTORICO',
                header: 'Historico',
                size: 300,
            }), 
            columnHelper.accessor('NK_PRODUTO', {
                id: 'NK_PRODUTO',
                header: 'Cod. Produto',
                size: 130,
            }), 
            columnHelper.accessor('DS_PRODUTO', {
                id: 'DS_PRODUTO',
                header: 'Produto',
                size: 120,
            }), 
            columnHelper.accessor('DD_TIPO_PRODUTO', {
                id: 'DD_TIPO_PRODUTO',
                header: 'Tipo Produto',
                size: 130,
            }), 
            columnHelper.accessor('DD_UNIDADE_MEDIDA', {
                id: 'DD_UNIDADE_MEDIDA',
                header: 'U.M.',
                size: 80,
            }), 
            columnHelper.accessor('DD_NCM', {
                id: 'DD_NCM',
                header: 'NCM',
                size: 80,
            }), 
            columnHelper.accessor('DD_CODIGO_CONDICAO_PAGAMENTO', {
                id: 'DD_CODIGO_CONDICAO_PAGAMENTO',
                header: 'Cod. Condição Pag.',
                size: 160,
            }), 
            columnHelper.accessor('DD_NM_CONDICAO_PAGAMENTO', {
                id: 'DD_NM_CONDICAO_PAGAMENTO',
                header: 'Condição Pagamento',
                size: 180,
            }), 
            columnHelper.accessor(({ DD_INTERCOMPANY }) =>
                DD_INTERCOMPANY
                    ? 'SIM'
                    : 'NÃO', 
                {
                id: 'DD_INTERCOMPANY',
                header: 'Intercompany?',
                size: 140,
            }), 
            columnHelper.accessor('DD_CNPJ', {
                id: 'DD_CNPJ',
                header: 'CNPJ',
                size: 140,
            }), 
            columnHelper.accessor('DD_PARCELA', {
                id: 'DD_PARCELA',
                header: 'Parcela',
                size: 100,
            }), 
            columnHelper.accessor('NM_PROPRIETARIO', {
                id: 'NM_PROPRIETARIO',
                header: 'Distribuidor',
                size: 200,
            }), 
            columnHelper.accessor('DD_PREFIXO', {
                id: 'DD_PREFIXO',
                header: 'Prefixo',
                size: 120,
            }), 
            columnHelper.accessor('DD_IDCARGA', {
                id: 'DD_IDCARGA',
                header: 'ID Carga',
                size: 120,
            }), 
            columnHelper.accessor(({ VL_MULTA_BAIXADA }) =>
                VL_MULTA_BAIXADA
                    ? currencyFormat(Number(VL_MULTA_BAIXADA))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_MULTA_BAIXADA',
                header: 'Valor Multa Baixada',
                size: 170,
            }),  
            columnHelper.accessor(({ VL_JUROS_BAIXADO }) =>
                VL_JUROS_BAIXADO
                    ? currencyFormat(Number(VL_JUROS_BAIXADO))
                    : '', {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'VL_JUROS_BAIXADO',
                header: 'Valor Juros Baixado',
                size: 160,
            }),  
            columnHelper.accessor('DD_CLASSIFICACAO', {
                id: 'DD_CLASSIFICACAO',
                header: 'Classificação',
                size: 180,
            }),  
        ],
        []
    )

    return (
        <>   
            <div className="flex flex-col h-full overflow-auto p-4">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/"
                                >
                                    Inicio
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Posição 1
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
 
                <div className="py-2 overflow-x-auto grid grid-cols-[repeat(7,minmax(220px,auto))] gap-4">
                    {summary?.map((detail, index) => (
                        <CardSummary 
                            key={index}
                            title={detail.title} 
                            value={detail.value}
                            observation={detail.observation}
                        /> 
                    ))}
                </div> 

                <div className="flex-1 overflow-hidden">
                    <Table<UnsettledSecuritiesTable>
                        data={memoData}
                        columns={memoColumns}
                        getRowId={(row) => row.SK_TITULO_A_RECEBER.toString()}
                        isLoading={isPending}
                        isFetching={isFetching}  
                        tableHeader={(
                            <div className="flex gap-4">
                                <TypographyH4 className="content-center">Títulos Posição 1</TypographyH4>
                                <div className="w-[200px]">
                                    {selectClassification && (
                                        <Select onValueChange={(value) => setSelectClassification(value)} defaultValue={selectClassification}>
                                            <SelectTrigger> 
                                                <SelectValue />
                                            </SelectTrigger>  
                                            <SelectContent className="w-[200px]">
                                            {classification?.map((option, index) => (  
                                                <SelectItem key={index} value={option}>{option}</SelectItem> 
                                            ))}
                                            </SelectContent>
                                        </Select>
                                    )}
                                    
                                </div>
                            </div>
                        )}
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-full gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button>
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)}
                                    variant="ghost"
                                >
                                    <ArrowDownToLine size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                    <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" /> 
                            </div>
                        )}
                        getTableInstance={(table) => { 
                            setTable(table)
                            const filterRow = table.getFilteredRowModel().flatRows.map(item => item.original)
                            setDataTableCurrent(filterRow)
                        }}   
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    /> 
                </div>
            </div> 
        </>
    )
}

export default Position1
