import { ProductTotalPosition, UnsettledSecuritiesTotalPosition } from "@/types/UnsettledSecurities"

interface Props { 
    uf: string[]
    distributor: string 
    data: UnsettledSecuritiesTotalPosition[]
    dataProduct: ProductTotalPosition[]
}

export const calculatePosition1DataDashboard = ({data, distributor, uf, dataProduct} : Props) => {
    
    const filteredDataDistributorInUF = data.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO) )
        const filteredDataDistributor = data.filter(item => item.NM_PROPRIETARIO == distributor)
        const filteredDataProductDistributorInUF = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
        const filteredDataProductDistributor = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor)
        

        const sumOverdueAmount = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_P1);
        }, 0);

        const sumAmountDueToday = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_HOJE_P1);
        }, 0);
        
        const sumAmountToMature = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_A_VENCER_P1);
        }, 0);

        const sumOverdueAmountAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_P1);
        }, 0);

        const sumAmountDueTodayAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P1);
        }, 0);
        
        const sumAmountToMatureAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_A_VENCER_ACORDO_P1);
        }, 0);

        const sumOverdueAmountTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_P1);
        }, 0);

        const sumAmountDueTodayTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_HOJE_P1);
        }, 0);
        
        const sumAmountToMatureTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_A_VENCER_P1);
        }, 0);

        const sumOverdueAmountAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_P1);
        }, 0);

        const sumAmountDueTodayAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P1);
        }, 0);
        
        const sumAmountToMatureAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_A_VENCER_ACORDO_P1);
        }, 0);

        const sumAmountProductTypePA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER_P1);
        }, 0);

        const sumAmountProductTypeAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER_P1);
        }, 0); 

        const sumAmountProductTypeDEMAIS = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER_P1);
        }, 0);


        const sumAmountProductTypePATotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER_P1);
        }, 0);

        const sumAmountProductTypeAITotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER_P1);
        }, 0); 

        const sumAmountProductTypeDEMAISTotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.VL_VENCIDO_E_A_VENCER_P1);
        }, 0);

        const sumAverageDaysPA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.DD_DIAS_UTEIS_P1);
        }, 0);

        const sumAverageDaysAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.DD_DIAS_UTEIS_P1);
        }, 0); 

        const sumAverageDaysDEMAIS = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS').reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.DD_DIAS_UTEIS_P1);
        }, 0); 


        const overdueAmount = sumOverdueAmount
        const amountDueToday = sumAmountDueToday
        const amountToMature = sumAmountToMature
        const totalInPortifolio = sumOverdueAmount + sumAmountDueToday + sumAmountToMature

        const overdueAmountAgreement = sumOverdueAmountAgreement
        const amountDueTodayAgreement = sumAmountDueTodayAgreement
        const amountToMatureAgreement  = sumAmountToMatureAgreement
        const fullyInAgreement = sumOverdueAmountAgreement + sumAmountDueTodayAgreement + sumAmountToMatureAgreement

        const overdueAmountpercentageOfRepresentation = sumOverdueAmountTotal > 0 ? (sumOverdueAmount / sumOverdueAmountTotal) * 100 : 0
        const amountDueTodaypercentageOfRepresentation = sumAmountDueTodayTotal > 0 ? (sumAmountDueToday / sumAmountDueTodayTotal) * 100 : 0
        const amountToMaturepercentageOfRepresentation = sumAmountToMatureTotal > 0 ? (sumAmountToMature / sumAmountToMatureTotal) * 100 : 0
        const totalInPortifolioOfRepresentation = (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) > 0 ? 
            (( totalInPortifolio ) / (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) ) * 100 : 0

        const overdueAmountAgreementpercentageOfRepresentation = sumOverdueAmountAgreementTotal > 0 ? (sumOverdueAmountAgreement / sumOverdueAmountAgreementTotal) * 100 : 0
        const amountDueTodayAgreementpercentageOfRepresentation = sumAmountDueTodayAgreementTotal > 0 ? (sumAmountDueTodayAgreement / sumAmountDueTodayAgreementTotal) * 100 : 0
        const amountToMatureAgreementpercentageOfRepresentation = sumAmountToMatureAgreementTotal > 0 ? (sumAmountToMatureAgreement / sumAmountToMatureAgreementTotal) * 100 : 0
        const fullyInAgreementOfRepresentation = (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) > 0 ? 
        (( fullyInAgreement ) / (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) ) * 100 : 0

        const amountProductTypePA = sumAmountProductTypePA
        const amountProductTypeAI = sumAmountProductTypeAI
        const amountProductTypeDEMAIS = sumAmountProductTypeDEMAIS
        const totalProducts = sumAmountProductTypePA + sumAmountProductTypeAI + sumAmountProductTypeDEMAIS

        const amountProductTypePAPercentageOfRepresentation = sumAmountProductTypePATotal > 0 ? (sumAmountProductTypePA / sumAmountProductTypePATotal) * 100 : 0
        const amountProductTypeAIPercentageOfRepresentation = sumAmountProductTypeAITotal > 0 ? (sumAmountProductTypeAI / sumAmountProductTypeAITotal) * 100 : 0 
        const amountProductTypeDEMAISPercentageOfRepresentation = sumAmountProductTypeDEMAISTotal > 0 ? (sumAmountProductTypeDEMAIS / sumAmountProductTypeDEMAISTotal) * 100 : 0 
        const totalProductOfRepresentation = (sumAmountProductTypePATotal + sumAmountProductTypeAITotal + sumAmountProductTypeDEMAISTotal) > 0 ? 
            (( totalProducts ) / (sumAmountProductTypePATotal + sumAmountProductTypeAITotal + sumAmountProductTypeDEMAISTotal) ) * 100 : 0

        const averageDaysPA = ((sumAverageDaysPA || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA').length || 0) || 0)
        const averageDaysAI = ((sumAverageDaysAI || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI').length || 0) || 0)
        const averageDaysDEMAIS = ((sumAverageDaysDEMAIS || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS').length || 0) || 0)
        const averageDaysTotal = (sumAverageDaysPA + sumAverageDaysAI + sumAverageDaysDEMAIS) / (filteredDataProductDistributorInUF.filter(item => ['DEMAIS','PA','AI'].includes(item.DD_TIPO_PRODUTO_P1 || '') ).length || 0) || 0

    
    return  {
        overdueAmount,
        amountDueToday,
        amountToMature,
        totalInPortifolio,

        overdueAmountAgreement,
        amountDueTodayAgreement,
        amountToMatureAgreement,
        fullyInAgreement,

        overdueAmountpercentageOfRepresentation,
        amountDueTodaypercentageOfRepresentation,
        amountToMaturepercentageOfRepresentation,
        totalInPortifolioOfRepresentation,

        overdueAmountAgreementpercentageOfRepresentation,
        amountDueTodayAgreementpercentageOfRepresentation,
        amountToMatureAgreementpercentageOfRepresentation,
        fullyInAgreementOfRepresentation,

        amountProductTypePA,
        amountProductTypeAI,
        amountProductTypeDEMAIS,
        totalProducts,

        amountProductTypePAPercentageOfRepresentation,
        amountProductTypeAIPercentageOfRepresentation,
        amountProductTypeDEMAISPercentageOfRepresentation,
        totalProductOfRepresentation,

        averageDaysPA,
        averageDaysAI,
        averageDaysDEMAIS,
        averageDaysTotal
    }
}

export const calculatePosition2DataDashboard = ({data, distributor, uf, dataProduct} : Props) => {
    
    const filteredDataDistributorInUF = data.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
    const filteredDataDistributor = data.filter(item => item.NM_PROPRIETARIO == distributor)
    const filteredDataProductDistributorInUF = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
    const filteredDataProductDistributor = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor)
    

    const sumOverdueAmount = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_P2);
    }, 0);

    const sumAmountDueToday = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_HOJE_P2);
    }, 0);
    
    const sumAmountToMature = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_P2);
    }, 0);

    const sumOverdueAmountAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_P2);
    }, 0);

    const sumAmountDueTodayAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P2);
    }, 0);
    
    const sumAmountToMatureAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_ACORDO_P2);
    }, 0);

    const sumOverdueAmountTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_P2);
    }, 0);

    const sumAmountDueTodayTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_HOJE_P2);
    }, 0);
    
    const sumAmountToMatureTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_P2);
    }, 0);

    const sumOverdueAmountAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_P2);
    }, 0);

    const sumAmountDueTodayAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P2);
    }, 0);
    
    const sumAmountToMatureAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_ACORDO_P2);
    }, 0);

    const sumAmountProductTypePA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P2 == 'PA').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P2);
    }, 0);

    const sumAmountProductTypeAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P2 == 'AI').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P2);
    }, 0); 

    const sumAmountProductTypePATotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P2 == 'PA').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P2);
    }, 0);

    const sumAmountProductTypeAITotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P2 == 'AI').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P2);
    }, 0); 

    const sumAverageDaysPA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P2 == 'PA').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.DD_DIAS_UTEIS_P2);
    }, 0);

    
    return  {
        overdueAmount : sumOverdueAmount,
        amountDueToday: sumAmountDueToday,
        amountToMature: sumAmountToMature,
        totalInPortifolio: sumOverdueAmount + sumAmountDueToday + sumAmountToMature,

        overdueAmountAgreement: sumOverdueAmountAgreement,
        amountDueTodayAgreement: sumAmountDueTodayAgreement,
        amountToMatureAgreement : sumAmountToMatureAgreement,
        fullyInAgreement: sumOverdueAmountAgreement + sumAmountDueTodayAgreement + sumAmountToMatureAgreement,

        overdueAmountpercentageOfRepresentation: sumOverdueAmountTotal > 0 ? (sumOverdueAmount / sumOverdueAmountTotal) * 100 : 0,
        amountDueTodaypercentageOfRepresentation: sumAmountDueTodayTotal > 0 ? (sumAmountDueToday / sumAmountDueTodayTotal) * 100 : 0,
        amountToMaturepercentageOfRepresentation: sumAmountToMatureTotal > 0 ? (sumAmountToMature / sumAmountToMatureTotal) * 100 : 0,
        totalInPortifolioOfRepresentation: (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) > 0 ? 
            ((sumOverdueAmount + sumAmountDueToday + sumAmountToMature) / (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) ) * 100 : 0,

        overdueAmountAgreementpercentageOfRepresentation: sumOverdueAmountAgreementTotal > 0 ? (sumOverdueAmountAgreement / sumOverdueAmountAgreementTotal) * 100 : 0,
        amountDueTodayAgreementpercentageOfRepresentation: sumAmountDueTodayAgreementTotal > 0 ? (sumAmountDueTodayAgreement / sumAmountDueTodayAgreementTotal) * 100 : 0,
        amountToMatureAgreementpercentageOfRepresentation: sumAmountToMatureAgreementTotal > 0 ? (sumAmountToMatureAgreement / sumAmountToMatureAgreementTotal) * 100 : 0,
        fullyInAgreementOfRepresentation: (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) > 0 ? 
        ((sumOverdueAmountAgreement + sumAmountDueTodayAgreement + sumAmountToMatureAgreement) / (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) ) * 100 : 0,
        
        amountProductTypePA: sumAmountProductTypePA,
        amountProductTypeAI: sumAmountProductTypeAI,
        amountProductTypePAPercentageOfRepresentation: sumAmountProductTypePATotal > 0 ? (sumAmountProductTypePA / sumAmountProductTypePATotal) * 100 : 0,
        amountProductTypeAIPercentageOfRepresentation: sumAmountProductTypeAITotal > 0 ? (sumAmountProductTypeAI / sumAmountProductTypeAITotal) * 100 : 0, 
        averageDaysPA: ((sumAverageDaysPA || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P2 == 'PA').length || 0) || 0),
        averageDaysAI: 0
    }
}

export const calculatePosition3DataDashboard = ({data, distributor, uf, dataProduct} : Props) => {
    const filteredDataDistributorInUF = data.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
    const filteredDataDistributor = data.filter(item => item.NM_PROPRIETARIO == distributor)
    const filteredDataProductDistributorInUF = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
    const filteredDataProductDistributor = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor)
    

    const sumOverdueAmount = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_P3);
    }, 0);

    const sumAmountDueToday = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_HOJE_P3);
    }, 0);
    
    const sumAmountToMature = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_P3);
    }, 0);

    const sumOverdueAmountAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_P3);
    }, 0);

    const sumAmountDueTodayAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P3);
    }, 0);
    
    const sumAmountToMatureAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_ACORDO_P3);
    }, 0);

    const sumOverdueAmountTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_P3);
    }, 0);

    const sumAmountDueTodayTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_HOJE_P3);
    }, 0);
    
    const sumAmountToMatureTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_P3);
    }, 0);

    const sumOverdueAmountAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_P3);
    }, 0);

    const sumAmountDueTodayAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P3);
    }, 0);
    
    const sumAmountToMatureAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_A_VENCER_ACORDO_P3);
    }, 0);

    const sumAmountProductTypePA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P3 == 'PA').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P3);
    }, 0);

    const sumAmountProductTypeAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P3 == 'AI').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P3);
    }, 0); 

    const sumAmountProductTypePATotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P3 == 'PA').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P3);
    }, 0);

    const sumAmountProductTypeAITotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P3 == 'AI').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.VL_TOTAL_COM_JUROS_P3);
    }, 0); 

    const sumAverageDaysPA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P3 == 'PA').reduce((accumulator, currentValue) => {
        return accumulator + Number(currentValue.DD_DIAS_UTEIS_P3);
    }, 0);

    
    return  {
        overdueAmount : sumOverdueAmount,
        amountDueToday: sumAmountDueToday,
        amountToMature: sumAmountToMature,
        totalInPortifolio: sumOverdueAmount + sumAmountDueToday + sumAmountToMature,

        overdueAmountAgreement: sumOverdueAmountAgreement,
        amountDueTodayAgreement: sumAmountDueTodayAgreement,
        amountToMatureAgreement : sumAmountToMatureAgreement,
        fullyInAgreement: sumOverdueAmountAgreement + sumAmountDueTodayAgreement + sumAmountToMatureAgreement,

        overdueAmountpercentageOfRepresentation: sumOverdueAmountTotal > 0 ? (sumOverdueAmount / sumOverdueAmountTotal) * 100 : 0,
        amountDueTodaypercentageOfRepresentation: sumAmountDueTodayTotal > 0 ? (sumAmountDueToday / sumAmountDueTodayTotal) * 100 : 0,
        amountToMaturepercentageOfRepresentation: sumAmountToMatureTotal > 0 ? (sumAmountToMature / sumAmountToMatureTotal) * 100 : 0,
        totalInPortifolioOfRepresentation: (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) > 0 ? 
            ((sumOverdueAmount + sumAmountDueToday + sumAmountToMature) / (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) ) * 100 : 0,

        overdueAmountAgreementpercentageOfRepresentation: sumOverdueAmountAgreementTotal > 0 ? (sumOverdueAmountAgreement / sumOverdueAmountAgreementTotal) * 100 : 0,
        amountDueTodayAgreementpercentageOfRepresentation: sumAmountDueTodayAgreementTotal > 0 ? (sumAmountDueTodayAgreement / sumAmountDueTodayAgreementTotal) * 100 : 0,
        amountToMatureAgreementpercentageOfRepresentation: sumAmountToMatureAgreementTotal > 0 ? (sumAmountToMatureAgreement / sumAmountToMatureAgreementTotal) * 100 : 0,
        fullyInAgreementOfRepresentation: (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) > 0 ? 
        ((sumOverdueAmountAgreement + sumAmountDueTodayAgreement + sumAmountToMatureAgreement) / (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) ) * 100 : 0,

        amountProductTypePA: sumAmountProductTypePA,
        amountProductTypeAI: sumAmountProductTypeAI,
        amountProductTypePAPercentageOfRepresentation: sumAmountProductTypePATotal > 0 ? (sumAmountProductTypePA / sumAmountProductTypePATotal) * 100 : 0,
        amountProductTypeAIPercentageOfRepresentation: sumAmountProductTypeAITotal > 0 ? (sumAmountProductTypeAI / sumAmountProductTypeAITotal) * 100 : 0, 
        averageDaysPA: ((sumAverageDaysPA || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P3 == 'PA').length || 0) || 0),
        averageDaysAI: 0
    }
}

export const calculatePositionTotalDataDashboard = ({data, distributor, uf, dataProduct} : Props) => {
    const filteredDataDistributorInUF = data.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
    const filteredDataDistributor = data.filter(item => item.NM_PROPRIETARIO == distributor)
    const filteredDataProductDistributorInUF = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor).filter(item => uf.includes(item.DD_ESTADO))
    const filteredDataProductDistributor = dataProduct.filter(item => item.NM_PROPRIETARIO == distributor)
  

    const sumOverdueAmount = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_P2) || 0) + (Number(currentValue.VL_VENCIDO_P1) || 0) + (Number(currentValue.VL_VENCIDO_P3)|| 0) ;
    }, 0);

    const sumAmountDueToday = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_HOJE_P2) || 0) + (Number(currentValue.VL_VENCIDO_HOJE_P1) || 0) + (Number(currentValue.VL_VENCIDO_HOJE_P3)|| 0) ;
    }, 0);
    
    const sumAmountToMature = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_A_VENCER_P2) || 0) + (Number(currentValue.VL_A_VENCER_P1) || 0) + (Number(currentValue.VL_A_VENCER_P3)|| 0) ;
    }, 0);

    const sumOverdueAmountAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_ACORDO_P2) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_P1) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_P3)|| 0) ;
    }, 0);

    const sumAmountDueTodayAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P2) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P1) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P3)|| 0) ;
    }, 0);
    
    const sumAmountToMatureAgreement = filteredDataDistributorInUF.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_A_VENCER_ACORDO_P2) || 0) + (Number(currentValue.VL_A_VENCER_ACORDO_P1) || 0) + (Number(currentValue.VL_A_VENCER_ACORDO_P3)|| 0) ;
    }, 0);

    const sumOverdueAmountTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_P2) || 0) + (Number(currentValue.VL_VENCIDO_P1) || 0) + (Number(currentValue.VL_VENCIDO_P3)|| 0) ;
    }, 0);

    const sumAmountDueTodayTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_HOJE_P2) || 0) + (Number(currentValue.VL_VENCIDO_HOJE_P1) || 0) + (Number(currentValue.VL_VENCIDO_HOJE_P3)|| 0) ;
    }, 0);
    
    const sumAmountToMatureTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_A_VENCER_P2) || 0) + (Number(currentValue.VL_A_VENCER_P1) || 0) + (Number(currentValue.VL_A_VENCER_P3)|| 0) ;
    }, 0);

    const sumOverdueAmountAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_ACORDO_P2) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_P1) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_P3)|| 0) ;
    }, 0);

    const sumAmountDueTodayAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P2) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P1) || 0) + (Number(currentValue.VL_VENCIDO_ACORDO_HOJE_P3)|| 0) ;
    }, 0);
    
    const sumAmountToMatureAgreementTotal = filteredDataDistributor.reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_A_VENCER_ACORDO_P2) || 0) + (Number(currentValue.VL_A_VENCER_ACORDO_P1) || 0) + (Number(currentValue.VL_A_VENCER_ACORDO_P3)|| 0) ;
    }, 0);

    const sumAmountProductTypePA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_E_A_VENCER_P1) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P2) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P3)|| 0);
    }, 0);

    const sumAmountProductTypeAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_E_A_VENCER_P1) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P2) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P3)|| 0);
    }, 0); 

    const sumAmountProductTypeDEMAIS = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS' || item.DD_TIPO_PRODUTO_P2 == "DEMAIS" || item.DD_TIPO_PRODUTO_P3 == "DEMAIS")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_E_A_VENCER_P1) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P2) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P3)|| 0);
    }, 0); 

    const sumAmountProductTypePATotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_E_A_VENCER_P1) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P2) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P3)|| 0);
    }, 0);

    const sumAmountProductTypeAITotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_E_A_VENCER_P1) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P2) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P3)|| 0);
    }, 0);

    const sumAmountProductTypeDEMAISTotal = filteredDataProductDistributor.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS' || item.DD_TIPO_PRODUTO_P2 == "DEMAIS" || item.DD_TIPO_PRODUTO_P3 == "DEMAIS")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.VL_VENCIDO_E_A_VENCER_P1) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P2) || 0) + (Number(currentValue.VL_TOTAL_COM_JUROS_P3)|| 0);
    }, 0);

    const sumAverageDaysPA = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA' || item.DD_TIPO_PRODUTO_P2 == "PA" || item.DD_TIPO_PRODUTO_P3 == "PA")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.DD_DIAS_UTEIS_P1) || 0) + (Number(currentValue.DD_DIAS_UTEIS_P2) || 0) + (Number(currentValue.DD_DIAS_UTEIS_P3)|| 0);
    }, 0);

    const sumAverageDaysAI = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI' || item.DD_TIPO_PRODUTO_P2 == "AI" || item.DD_TIPO_PRODUTO_P3 == "AI")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.DD_DIAS_UTEIS_P1) || 0) + (Number(currentValue.DD_DIAS_UTEIS_P2) || 0) + (Number(currentValue.DD_DIAS_UTEIS_P3)|| 0);
    }, 0); 

    const sumAverageDaysDEMAIS = filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS' || item.DD_TIPO_PRODUTO_P2 == "DEMAIS" || item.DD_TIPO_PRODUTO_P3 == "DEMAIS")
    .reduce((accumulator, currentValue) => {
        return accumulator + (Number(currentValue.DD_DIAS_UTEIS_P1) || 0) + (Number(currentValue.DD_DIAS_UTEIS_P2) || 0) + (Number(currentValue.DD_DIAS_UTEIS_P3)|| 0);
    }, 0); 


    const overdueAmount = sumOverdueAmount
        const amountDueToday = sumAmountDueToday
        const amountToMature = sumAmountToMature
        const totalInPortifolio = sumOverdueAmount + sumAmountDueToday + sumAmountToMature

        const overdueAmountAgreement = sumOverdueAmountAgreement
        const amountDueTodayAgreement = sumAmountDueTodayAgreement
        const amountToMatureAgreement  = sumAmountToMatureAgreement
        const fullyInAgreement = sumOverdueAmountAgreement + sumAmountDueTodayAgreement + sumAmountToMatureAgreement

        const overdueAmountpercentageOfRepresentation = sumOverdueAmountTotal > 0 ? (sumOverdueAmount / sumOverdueAmountTotal) * 100 : 0
        const amountDueTodaypercentageOfRepresentation = sumAmountDueTodayTotal > 0 ? (sumAmountDueToday / sumAmountDueTodayTotal) * 100 : 0
        const amountToMaturepercentageOfRepresentation = sumAmountToMatureTotal > 0 ? (sumAmountToMature / sumAmountToMatureTotal) * 100 : 0
        const totalInPortifolioOfRepresentation = (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) > 0 ? 
            (( totalInPortifolio ) / (sumOverdueAmountTotal + sumAmountDueTodayTotal + sumAmountToMatureTotal) ) * 100 : 0

        const overdueAmountAgreementpercentageOfRepresentation = sumOverdueAmountAgreementTotal > 0 ? (sumOverdueAmountAgreement / sumOverdueAmountAgreementTotal) * 100 : 0
        const amountDueTodayAgreementpercentageOfRepresentation = sumAmountDueTodayAgreementTotal > 0 ? (sumAmountDueTodayAgreement / sumAmountDueTodayAgreementTotal) * 100 : 0
        const amountToMatureAgreementpercentageOfRepresentation = sumAmountToMatureAgreementTotal > 0 ? (sumAmountToMatureAgreement / sumAmountToMatureAgreementTotal) * 100 : 0
        const fullyInAgreementOfRepresentation = (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) > 0 ? 
        (( fullyInAgreement ) / (sumOverdueAmountAgreementTotal + sumAmountDueTodayAgreementTotal + sumAmountToMatureAgreementTotal) ) * 100 : 0

        const amountProductTypePA = sumAmountProductTypePA
        const amountProductTypeAI = sumAmountProductTypeAI
        const amountProductTypeDEMAIS = sumAmountProductTypeDEMAIS
        const totalProducts = sumAmountProductTypePA + sumAmountProductTypeAI + sumAmountProductTypeDEMAIS

        const amountProductTypePAPercentageOfRepresentation = sumAmountProductTypePATotal > 0 ? (sumAmountProductTypePA / sumAmountProductTypePATotal) * 100 : 0
        const amountProductTypeAIPercentageOfRepresentation = sumAmountProductTypeAITotal > 0 ? (sumAmountProductTypeAI / sumAmountProductTypeAITotal) * 100 : 0 
        const amountProductTypeDEMAISPercentageOfRepresentation = sumAmountProductTypeDEMAISTotal > 0 ? (sumAmountProductTypeDEMAIS / sumAmountProductTypeDEMAISTotal) * 100 : 0 
        const totalProductOfRepresentation = (sumAmountProductTypePATotal + sumAmountProductTypeAITotal + sumAmountProductTypeDEMAISTotal) > 0 ? 
            (( totalProducts ) / (sumAmountProductTypePATotal + sumAmountProductTypeAITotal + sumAmountProductTypeDEMAISTotal) ) * 100 : 0

        const averageDaysPA = ((sumAverageDaysPA || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'PA').length || 0) || 0)
        const averageDaysAI = ((sumAverageDaysAI || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'AI').length || 0) || 0)
        const averageDaysDEMAIS = ((sumAverageDaysDEMAIS || 0) / (filteredDataProductDistributorInUF.filter(item => item.DD_TIPO_PRODUTO_P1 == 'DEMAIS').length || 0) || 0)
        const averageDaysTotal = (sumAverageDaysPA + sumAverageDaysAI + sumAverageDaysDEMAIS) / (filteredDataProductDistributorInUF.filter(item => ['DEMAIS','PA','AI'].includes(item.DD_TIPO_PRODUTO_P1 || '') ).length || 0) || 0

    
    return  {
        overdueAmount,
        amountDueToday,
        amountToMature,
        totalInPortifolio,

        overdueAmountAgreement,
        amountDueTodayAgreement,
        amountToMatureAgreement,
        fullyInAgreement,

        overdueAmountpercentageOfRepresentation,
        amountDueTodaypercentageOfRepresentation,
        amountToMaturepercentageOfRepresentation,
        totalInPortifolioOfRepresentation,

        overdueAmountAgreementpercentageOfRepresentation,
        amountDueTodayAgreementpercentageOfRepresentation,
        amountToMatureAgreementpercentageOfRepresentation,
        fullyInAgreementOfRepresentation,

        amountProductTypePA,
        amountProductTypeAI,
        amountProductTypeDEMAIS,
        totalProducts,

        amountProductTypePAPercentageOfRepresentation,
        amountProductTypeAIPercentageOfRepresentation,
        amountProductTypeDEMAISPercentageOfRepresentation,
        totalProductOfRepresentation,

        averageDaysPA,
        averageDaysAI,
        averageDaysDEMAIS,
        averageDaysTotal
    }
}