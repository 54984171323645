import { UnsettledSecuritiesTotalPosition, ProductTotalPosition } from "@/types/UnsettledSecurities"
import { useEffect, useState } from "react"
import Position2Text from "./componentes/text" 
import { calculatePosition2DataDashboard } from "@/utils/calculateDataDashboard"

interface PositionProps {
    data: UnsettledSecuritiesTotalPosition[] 
    dataProduct: ProductTotalPosition[]
    uf: string[]
    distributor: string  
} 

type informationProps = {
    overdueAmount: number
    amountDueToday: number
    amountToMature: number
    
    totalInPortifolio: number
    overdueAmountAgreement: number
    amountDueTodayAgreement: number
    amountToMatureAgreement: number
    fullyInAgreement: number
    
    overdueAmountpercentageOfRepresentation: number
    amountDueTodaypercentageOfRepresentation: number
    amountToMaturepercentageOfRepresentation: number
    totalInPortifolioOfRepresentation: number
    
    overdueAmountAgreementpercentageOfRepresentation: number
    amountDueTodayAgreementpercentageOfRepresentation: number
    amountToMatureAgreementpercentageOfRepresentation: number
    fullyInAgreementOfRepresentation: number
    
    amountProductTypePA: number
    amountProductTypeAI: number
    amountProductTypePAPercentageOfRepresentation: number
    amountProductTypeAIPercentageOfRepresentation: number
    averageDaysPA: number
}
 

const Position2Dashboard = ({data, dataProduct, uf, distributor} : PositionProps) => {  

    const [information, setinformation] = useState<informationProps>({
        overdueAmount : 0,
        amountDueToday: 0,
        amountToMature: 0,
        totalInPortifolio: 0,

        overdueAmountAgreement: 0,
        amountDueTodayAgreement: 0,
        amountToMatureAgreement : 0,
        fullyInAgreement: 0,

        overdueAmountpercentageOfRepresentation: 0,
        amountDueTodaypercentageOfRepresentation: 0,
        amountToMaturepercentageOfRepresentation: 0,
        totalInPortifolioOfRepresentation: 0,

        overdueAmountAgreementpercentageOfRepresentation: 0,
        amountDueTodayAgreementpercentageOfRepresentation: 0,
        amountToMatureAgreementpercentageOfRepresentation: 0,
        fullyInAgreementOfRepresentation: 0,

        amountProductTypePA: 0,
        amountProductTypeAI: 0,
        amountProductTypePAPercentageOfRepresentation: 0,
        amountProductTypeAIPercentageOfRepresentation: 0,
        averageDaysPA: 0
    }) 
    
    useEffect(() => {   
        setinformation( calculatePosition2DataDashboard({data, distributor, uf, dataProduct}) )  
    },[data, uf, distributor])

    return (
        <>
            {information && (
                <Position2Text information={information} distributor={distributor} uf={uf}/>
            )} 
        </>     
    )
}

export default Position2Dashboard
