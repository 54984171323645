import Table from '@/components/Table'
import { TableData } from '@/components/Table/type'
import { useBankStatmentMovimentationQuery } from '@/queries/useBankStatementMovimentationQuery'
import { useRoutsResaleQuery } from '@/queries/useRoutsResaleQuery'
import { useOwnerResaleQuery } from '@/queries/usOwnerResaleQuery'
import { Movimentation } from '@/types/BankStatement'
import { createColumnHelper } from '@tanstack/react-table'
import { useMemo, useState } from 'react'   
import DefaultColumn from '@/components/Table/components/DefaultColumn'
import Button from "@/components/Button"
import ButtonWithTooltip from "@/components/ButtonWithTooltip"
import { ArrowDownToLine, FilterX, RefreshCcw, Search, ChevronsUpDown, FileText } from 'lucide-react'
import { TypographyH4 } from '@/components/ui/typography'
import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from '@/components/ui/breadcrumb' 
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
  } from "@/components/ui/select"
import { Link } from 'react-router-dom' 
import { currencyFormat } from '@/utils/stringFormatter'
import { getDateFromId, getIdFromDate } from '@/utils/date'
import { format } from 'date-fns'
import { DatePicker } from '@/components/DatePicker'
import { useTable } from '@/hooks/useTable'
import { Combobox } from '@/components/Combobox'
import { useToast } from '@/components/ui/use-toast'
import { useMovimentationSpreadsheets } from './useMovimentationSpreadsheets'
import useDisclosure from '@/hooks/useDisclosure'
import ViewPDF from './viewPDF'

export type MovimentationTable = TableData<Movimentation>

const columnHelper = createColumnHelper<Movimentation>()

const BankStatementMovimentation = () => {
    const {
        table,  
        setTable,
    } = useTable<Movimentation>('') 
    const { toast } = useToast()

    const [enableStatus, setEnableStatus] = useState(false)
    const [selectResale, setSelectResale] = useState<string>('')
    const [selectOwner, setSelectOwner] = useState<string>('')
    const [selectType, setSelectType] = useState<string>('Revenda')
    const typesSelect = ["Distribuidor","Revenda"]

    const [dateInitial, setDateInitial] = useState<Date>(() => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 30);
        return currentDate
    });
    const [dateEnd, setDateEnd] = useState<Date>(new Date())
    const { onDownload } = useMovimentationSpreadsheets()

    const {
        isOpen: isDialogOpen,
        onClose: onDialogClose,
        onOpen: onDialogOpen,
    } = useDisclosure()


    const handleFetchMovimentation = () => { 
        if(verifyVariablesForFetchIsCorrety() == false) return

        if(enableStatus){
            setEnableStatus(true)
        } else {
            refetch()
        }
    }

    const verifyVariablesForFetchIsCorrety = () => {
        if (selectResale == '' && selectType == 'Revenda'){
            toast({
                title: 'Preenchimento de parâmetros.',
                variant:'destructive',
                description:
                    'Necessário selecionar uma Revenda!',
            })
            return false
        } else if (selectOwner == '' && selectType == 'Distribuidor'){
            toast({
                title: 'Preenchimento de parâmetros.',
                variant:'destructive',
                description:
                    'Necessário selecionar um Distribuidor!',
            })
            return false
        }
        return true
    }

    const { data, isFetching, isLoading, refetch } = useBankStatmentMovimentationQuery({X_CHAVE: selectType == 'Revenda' ? selectResale : selectOwner, X_TYPE: selectType, SK_TEMPO_INICIO: getIdFromDate(dateInitial).join(''), SK_TEMPO_FIM: getIdFromDate(dateEnd).join(''), enableStatus})
    const { data: routsResale  } = useRoutsResaleQuery()
    const { data: ownerResale  } = useOwnerResaleQuery()
     
    const memoData = useMemo(() => data || [], [data])
    const memoColumns = useMemo(
        () => [ 
            columnHelper.accessor(({ DATA }) =>
                DATA
                    ? format(getDateFromId(Number(DATA)), 'dd/MM/yyyy')
                    : '', {
                sortingFn: (rowA, rowB, _columnId) => {  
                    const statusA = Number(rowA.original[_columnId])
                    const statusB = Number(rowB.original[_columnId])
                    return statusA < statusB ? 1 : -1
                },
                id: 'DATA',
                header: 'Data',
                size: 100,
            }), 
            columnHelper.accessor('DS_ROTA', {
                id: 'DS_ROTA',
                header: 'Revenda',
                size: 260,
            }),  
            columnHelper.accessor('DD_CODIGO_NATUREZA_FINANCEIRA', {
                id: 'DD_CODIGO_NATUREZA_FINANCEIRA',
                header: 'Cod. Nat.',
                size: 110,
            }),  
            columnHelper.accessor('DD_NATUREZA_FINANCEIRA', {
                id: 'DD_NATUREZA_FINANCEIRA',
                header: 'Natureza',
                size: 200,
            }),  
            columnHelper.accessor('DS_HISTORICO', {
                id: 'DS_HISTORICO',
                header: 'Histórico',
                size: 340,
            }),  
            columnHelper.accessor(({ ENTRADA }) =>
                ENTRADA
                    ? currencyFormat(Number(ENTRADA))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'ENTRADA',
                header: 'Valor Entrada',
                size: 140,
            }),  
            
            columnHelper.accessor(({ SAIDA }) =>
                SAIDA
                    ? currencyFormat(Number(SAIDA))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SAIDA',
                header: 'Valor Saída',
                size: 140,
            }),  
            columnHelper.accessor(({ SALDO }) =>
                SALDO
                    ? currencyFormat(Number(SALDO))
                    : currencyFormat(0), {
                cell: props => (
                    <p className="text-right w-full">{`${props.getValue() || ''}`}</p>
                ),
                sortingFn: (rowA, rowB, _columnId) => {
                    const statusA = rowA.original[_columnId]
                    const statusB = rowB.original[_columnId]
                    return Number(statusA) < Number(statusB) ? 1 : -1
                },
                id: 'SALDO',
                header: 'Valor Saldo',
                size: 140,
            }),  
             
        ],
        []
    )

    return (
        <>
            <div className="flex flex-col h-full overflow-auto p-4">
                <Breadcrumb>
                    <BreadcrumbList>
                        <BreadcrumbItem>
                            <BreadcrumbLink asChild>
                                <Link
                                    className="hover:underline"
                                    to="/"
                                >
                                    Inicio
                                </Link>
                            </BreadcrumbLink>
                        </BreadcrumbItem>
                        <BreadcrumbSeparator />
                        <BreadcrumbItem>
                            <BreadcrumbPage>
                                Extrato Revenda - Movimentação Bancária Posição 3
                            </BreadcrumbPage>
                        </BreadcrumbItem>
                    </BreadcrumbList>
                </Breadcrumb>
                <div className='w-full px-4 pt-2 flex gap-4'> 
                    <div className="space-y-2 flex flex-col mt-2 w-[140px]">
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Filtrar por</label> 
                        <Select onValueChange={(value) => setSelectType(value)} defaultValue={selectType}>
                            <SelectTrigger> 
                                <SelectValue />
                            </SelectTrigger>  
                            <SelectContent className="w-[200px]">
                            {typesSelect?.map((option, index) => (  
                                <SelectItem key={index} value={option}>{option}</SelectItem> 
                            ))}
                            </SelectContent>
                        </Select>
                    </div>
                    {
                        selectType == 'Revenda'? (
                            <div className="space-y-2 flex flex-col mt-2 w-[240px]">
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Revenda</label> 
                                {routsResale && (
                                    <Combobox  
                                        data={routsResale?.map(item => {return {value: item.X_CHAVE_REVENDA, label: item.DS_ROTA }})} 
                                        title="Rota" 
                                        titleSelect='Seleciona uma rota' 
                                        getSelectData={(data)=> {
                                            setSelectResale(data)
                                        }}
                                    />
                                )} 
                            </div>
                        ) : ''
                    }
                    {
                        selectType == 'Distribuidor'? (
                            <div className="space-y-2 flex flex-col mt-2 w-[240px]">
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Distribuidor</label> 
                                {ownerResale && (
                                    <Combobox  
                                        data={ownerResale?.map(item => {return {value: item.NM_PROPRIETARIO, label: item.NM_PROPRIETARIO }})} 
                                        title="Rota" 
                                        titleSelect='Seleciona um Distribuidor' 
                                        getSelectData={(data)=> {
                                            setSelectOwner(data)
                                        }}
                                    />
                                )} 
                            </div>
                        ) : ''
                    }
                   
                    <div className="space-y-2 flex flex-col mt-2">
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Data inicial da Movimentação</label>
                        <DatePicker date={dateInitial} setDate={(data) => {
                            if(data) setDateInitial(data)
                        }}/>
                    </div>
                    <div className="space-y-2 flex flex-col mt-2">
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">Data final da Movimentação</label>
                        <DatePicker date={dateEnd} setDate={(data) => {
                            if(data) setDateEnd(data)
                        }}/>
                    </div>
                    <div className="space-y-2 flex flex-col mt-2 justify-end"> 
                        <Button  
                            size="sm" 
                            onClick={() => handleFetchMovimentation()}
                        > 
                            <Search className='h-[18px]'/>
                            Pesquisar 
                        </Button>
                    </div>

                </div>
                <div className="w-full h-full p-4 overflow-hidden">
                    <Table<MovimentationTable>
                        data={memoData}
                        columns={memoColumns}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        tableState={{grouping: ['DS_ROTA']}}
                        expandAllRows={true}
                        getTableInstance={(table) => setTable(table)}   
                        tableHeader={(
                            <TypographyH4>Movimentação Bancária</TypographyH4>
                        )}  
                        tableActions={(
                            <div className="flex items-center h-full gap-1.5">
                                <Button
                                    className="flex items-center w-[220px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.resetColumnFilters()}
                                >
                                    <FilterX size={14} />
                                    Remover Todos os Filtros
                                </Button>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <Button 
                                    className="flex items-center w-[225px] gap-4 h-[40px] justify-start text-sm hover:text-primary-500 hover:bg-accent"
                                    variant={'ghost'}
                                    onClick={() => table && table.toggleAllRowsExpanded()}
                                >
                                    <ChevronsUpDown size={14}/>
                                    Expandir Todas as Colunas
                                </Button>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Visualizar PDF</p>}
                                    variant={'ghost'}
                                    onClick={() => onDialogOpen()}
                                >
                                    <FileText size={14} />
                                    
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" /> 
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Download</p>}
                                    onClick={() => table && onDownload(table)}
                                    variant="ghost"
                                >
                                <ArrowDownToLine size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />  
                                <ButtonWithTooltip
                                    tooltipContent={<p className="text-white">Atualizar</p>}
                                    onClick={() => refetch()}
                                    variant="ghost"
                                >
                                <RefreshCcw size={18} />
                                </ButtonWithTooltip>
                                <div className="w-[1px] h-4 bg-neutral-300" />
                                
                            </div>
                        )}     
                        defaultColumn={{
                            cell: ({ getValue }) => (
                                <DefaultColumn>{getValue() as string}</DefaultColumn>
                            ),
                        }}
                    /> 
                    
                    {data && (<ViewPDF data={data} isOpen={isDialogOpen} onClose={onDialogClose} />)}
                </div>
            </div>
        </>
    )
}

export default BankStatementMovimentation
