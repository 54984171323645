import { Table } from '@tanstack/react-table'
import { UnsettledSecuritiesTable } from '.'
import { utils, writeFile } from 'xlsx'
import { UnsettledSecuritiesTotalPosition } from '@/types/UnsettledSecurities'

export const useTotalPositionSpreadsheets = () => {
    const getTableRows = (table: Table<UnsettledSecuritiesTable>): Partial<UnsettledSecuritiesTotalPosition>[] =>
        table.getCoreRowModel().rows.map((row) => {
            const obj = {} as Record<string, any> 

            obj["Revenda"] = row.original.DS_ROTA
            obj["Distribuidor"] = row.original.NM_PROPRIETARIO
            obj["Município"] = row.original.NM_MUNICIPIO
            obj["Cod. Cliente"] = row.original.DD_CLIENTE
            obj["Cliente"] = row.original.DS_REVENDA_GRADE
            obj["UF"] = row.original.DD_ESTADO
            obj["Limite Crédito"] = Number(row.original.DD_LIMITE)
            obj["Tipo Garantia"] = row.original.DD_TIPO_GARANTIA
            obj["Valor"] = row.original.DD_VALOR
            obj["1º Observação"] = row.original.DS_OBSERVACAO1
            obj["2º Observação"] = row.original.DS_OBSERVACAO2
            obj["P1 - Total a Vencer"] = row.original.VL_A_VENCER_P1
            obj["P1 - Total Vencido"] = row.original.VL_VENCIDO_P1
            obj["P1 - Vencimento Hoje"] = row.original.VL_VENCIDO_HOJE_P1
            obj["P1 - Total em Aberto"] = row.original.VL_VENCIDO_E_A_VENCER_P1
            obj["P1 Acordo - Total a Vencer"] = row.original.VL_A_VENCER_ACORDO_P1
            obj["P1 Acordo - Total Vencido"] = row.original.VL_VENCIDO_ACORDO_P1
            obj["P1 Acordo - Vencimento Hoje"] = row.original.VL_VENCIDO_ACORDO_HOJE_P1
            obj["P1 Acordo - Total em Aberto"] = row.original.VL_VENCIDO_E_A_VENCER_ACORDO_P1
            obj["P2 - Total a Vencer"] = row.original.VL_A_VENCER_P2
            obj["P2 - Total Vencido"] = row.original.VL_VENCIDO_P2
            obj["P2 - Vencimento Hoje"] = row.original.VL_VENCIDO_HOJE_P2
            obj["P2 - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_P2
            obj["P2 - Total Multa"] = row.original.VL_MULTA_P2
            obj["P2 - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_P2
            obj["P2 Acordo - Total a Vencer"] = row.original.VL_A_VENCER_ACORDO_P2
            obj["P2 Acordo - Total Vencido"] = row.original.VL_VENCIDO_ACORDO_P2
            obj["P2 Acordo - Vencimento Hoje"] = row.original.VL_VENCIDO_ACORDO_HOJE_P2
            obj["P2 Acordo - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_ACORDO_P2
            obj["P2 Acordo - Total Multa"] = row.original.VL_MULTA_ACORDO_P2
            obj["P2 Acordo - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_ACORDO_P2
            obj["P3 - Total a Vencer"] = row.original.VL_A_VENCER_P3
            obj["P3 - Total Vencido"] = row.original.VL_VENCIDO_P3
            obj["P3 - Vencimento Hoje"] = row.original.VL_VENCIDO_HOJE_P3
            obj["P3 - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_P3
            obj["P3 - Total Multa"] = row.original.VL_MULTA_P3
            obj["P3 - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_P3
            obj["P3 Acordo - Total a Vencer"] = row.original.VL_A_VENCER_ACORDO_P3
            obj["P3 Acordo - Total Vencido"] = row.original.VL_VENCIDO_ACORDO_P3
            obj["P3 Acordo - Vencimento Hoje"] = row.original.VL_VENCIDO_ACORDO_HOJE_P3
            obj["P3 Acordo - Juros Pelo Prazo"] = row.original.VL_JUROS_PELO_PRAZO_ACORDO_P3
            obj["P3 Acordo - Total Multa"] = row.original.VL_MULTA_ACORDO_P3
            obj["P3 Acordo - Total em Aberto"] = row.original.VL_TOTAL_COM_JUROS_ACORDO_P3
            obj["Extrato"] = row.original.EXTRATO
            obj["Extrato Menos Vencidos"] = row.original.EXTRATO_MENOS_VENCIDOS
            obj["Extrato Menos Venc. e Á Vencer"] = row.original.EXTRATO_MENOS_VENCIDOS_E_A_VENCER
            obj["Total a Vencer"] = row.original.TOTAL_A_VENCER
            obj["Total Vencido"] = row.original.TOTAL_VENCIDO
            obj["Total Em Aberto"] = row.original.TOTAL_EM_ABERTO
            obj["Acordo - Total a Vencer"] = row.original.TOTAL_A_VENCER_ACORDO
            obj["Acordo - Total Vencido"] = row.original.TOTAL_VENCIDO_ACORDO
            obj["Acordo - Total Em Aberto"] = row.original.TOTAL_EM_ABERTO_ACORDO
            obj["Sit. S/Acordo - Limite Disp."] = row.original.SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL
            obj["Sit. S/Acordo - Á Vencer"] = row.original.SITUACAO_SEM_ACORDO_A_VENCER
            obj["Sit. S/Acordo - Vencido"] = row.original.SITUACAO_SEM_ACORDO_VENCIDO
            obj["Sit. S/Acordo - Total em Aberto"] = row.original.SITUACAO_SEM_ACORDO_TOTAL_EM_ABERTO

            let SITUACAO_SEM_ACORDO_STATUS = "LIBERADO"
            let SITUACAO_SEM_ACORDO_VENCIDO = row.original.SITUACAO_SEM_ACORDO_VENCIDO || 0
            let TOTAL_VENCIDO = row.original.TOTAL_VENCIDO || 0
            let SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL = row.original.SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL || 0
            if(SITUACAO_SEM_ACORDO_VENCIDO > 0 || TOTAL_VENCIDO > 1 || SITUACAO_SEM_ACORDO_LIMITE_DISPONIVEL < -1){
                SITUACAO_SEM_ACORDO_STATUS = "BLOQUEADO"
            }
            obj["Sit. S/Acordo - STATUS"] = SITUACAO_SEM_ACORDO_STATUS

            obj["Sit. C/Acordo - Limite Disp."] = row.original.SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL

            let SITUACAO_COM_ACORDO_STATUS = "LIBERADO"
            let SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL = row.original.SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL || 0
            if(TOTAL_VENCIDO > 1 || SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL < -1){
                SITUACAO_COM_ACORDO_STATUS = "BLOQUEADO"
            }
            obj["Sit. C/Acordo - STATUS"] = SITUACAO_COM_ACORDO_STATUS

            let SITUACAO_COM_ACORDO_CHECK_LIMITE = "FALSO" 
            if(TOTAL_VENCIDO == SITUACAO_COM_ACORDO_LIMITE_DISPONIVEL){
                SITUACAO_COM_ACORDO_CHECK_LIMITE = "VERDADEIRO"
            }

            obj["Sit. C/Acordo - Check Limite"] = SITUACAO_COM_ACORDO_CHECK_LIMITE

            let SITUACAO_COM_ACORDO_TEM_SALDO = "NÃO"
            let EXTRATO = row.original.EXTRATO || 0 
            if(EXTRATO != 0){
                SITUACAO_COM_ACORDO_TEM_SALDO = "SIM"
            }
            obj["Sit. C/Acordo - Tem Saldo"] = SITUACAO_COM_ACORDO_TEM_SALDO

            let SITUACAO_COM_ACORDO_INADIMPLENCIA = "NÃO"
            let VL_VENCIDO_P1 = row.original.VL_VENCIDO_P1 || 0 
            let VL_VENCIDO_P2 = row.original.VL_VENCIDO_P2 || 0 
            let VL_JUROS_PELO_PRAZO_P2 = row.original.VL_JUROS_PELO_PRAZO_P2 || 0 
            let VL_MULTA_P2 = row.original.VL_MULTA_P2 || 0 
            let VL_VENCIDO_P3 = row.original.VL_VENCIDO_P3 || 0 
            let VL_JUROS_PELO_PRAZO_P3 = row.original.VL_JUROS_PELO_PRAZO_P3 || 0 
            let VL_MULTA_P3 = row.original.VL_MULTA_P3 || 0 

            if((VL_VENCIDO_P1 + VL_VENCIDO_P2 + VL_JUROS_PELO_PRAZO_P2 + VL_MULTA_P2 + VL_VENCIDO_P3 + VL_JUROS_PELO_PRAZO_P3 +VL_MULTA_P3) > 0){
                SITUACAO_COM_ACORDO_INADIMPLENCIA = "SIM"
            }
            obj["Sit. C/Acordo - Inadimplência"] = SITUACAO_COM_ACORDO_INADIMPLENCIA

            return obj
        })

    const onDownload = (tableInstance: Table<UnsettledSecuritiesTable>) => {
         
        const rows = getTableRows(tableInstance)
        const colluns = Object.keys(rows[0]).length;
 
        const formatMoneyAccounting = '_-* #,##0.00_-;-* #,##0.00_-;_-* "-"??_-;_-@_-'

        const worksheet = utils.aoa_to_sheet([[]]);
         
        worksheet['K1'] = { t: 's', v: 'Totais: ' }
        worksheet['L1'] = { t: 'n', f: `SUBTOTAL(9, L3:L${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['M1'] = { t: 'n', f: `SUBTOTAL(9, M3:M${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['N1'] = { t: 'n', f: `SUBTOTAL(9, N3:N${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['O1'] = { t: 'n', f: `SUBTOTAL(9, O3:O${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['P1'] = { t: 'n', f: `SUBTOTAL(9, P3:P${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['Q1'] = { t: 'n', f: `SUBTOTAL(9, Q3:Q${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['R1'] = { t: 'n', f: `SUBTOTAL(9, R3:R${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['S1'] = { t: 'n', f: `SUBTOTAL(9, S3:S${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['T1'] = { t: 'n', f: `SUBTOTAL(9, T3:T${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['U1'] = { t: 'n', f: `SUBTOTAL(9, U3:U${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['V1'] = { t: 'n', f: `SUBTOTAL(9, V3:V${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['W1'] = { t: 'n', f: `SUBTOTAL(9, W3:W${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['X1'] = { t: 'n', f: `SUBTOTAL(9, X3:X${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['Y1'] = { t: 'n', f: `SUBTOTAL(9, Y3:Y${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['Z1'] = { t: 'n', f: `SUBTOTAL(9, Z3:Z${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AA1'] = { t: 'n', f: `SUBTOTAL(9, AA3:AA${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AB1'] = { t: 'n', f: `SUBTOTAL(9, AB3:AB${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AC1'] = { t: 'n', f: `SUBTOTAL(9, AC3:AC${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AD1'] = { t: 'n', f: `SUBTOTAL(9, AD3:AD${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AE1'] = { t: 'n', f: `SUBTOTAL(9, AE3:AE${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AF1'] = { t: 'n', f: `SUBTOTAL(9, AF3:AF${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AG1'] = { t: 'n', f: `SUBTOTAL(9, AG3:AG${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AH1'] = { t: 'n', f: `SUBTOTAL(9, AH3:AH${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AI1'] = { t: 'n', f: `SUBTOTAL(9, AI3:AI${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AJ1'] = { t: 'n', f: `SUBTOTAL(9, AJ3:AJ${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AK1'] = { t: 'n', f: `SUBTOTAL(9, AK3:AK${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AL1'] = { t: 'n', f: `SUBTOTAL(9, AL3:AL${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AM1'] = { t: 'n', f: `SUBTOTAL(9, AM3:AM${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AN1'] = { t: 'n', f: `SUBTOTAL(9, AN3:AN${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AO1'] = { t: 'n', f: `SUBTOTAL(9, AO3:AO${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AP1'] = { t: 'n', f: `SUBTOTAL(9, AP3:AP${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AQ1'] = { t: 'n', f: `SUBTOTAL(9, AQ3:AQ${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AR1'] = { t: 'n', f: `SUBTOTAL(9, AR3:AR${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AS1'] = { t: 'n', f: `SUBTOTAL(9, AS3:AS${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AT1'] = { t: 'n', f: `SUBTOTAL(9, AT3:AT${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AU1'] = { t: 'n', f: `SUBTOTAL(9, AU3:AU${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AW1'] = { t: 'n', f: `SUBTOTAL(9, AW3:AW${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AV1'] = { t: 'n', f: `SUBTOTAL(9, AV3:AV${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AW1'] = { t: 'n', f: `SUBTOTAL(9, AW3:AW${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AX1'] = { t: 'n', f: `SUBTOTAL(9, AX3:AX${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AY1'] = { t: 'n', f: `SUBTOTAL(9, AY3:AY${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['AZ1'] = { t: 'n', f: `SUBTOTAL(9, AZ3:AZ${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['BA1'] = { t: 'n', f: `SUBTOTAL(9, BA3:BA${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['BB1'] = { t: 'n', f: `SUBTOTAL(9, BB3:BB${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['BC1'] = { t: 'n', f: `SUBTOTAL(9, BC3:BC${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['BD1'] = { t: 'n', f: `SUBTOTAL(9, BD3:BD${rows.length + 3})`, z: formatMoneyAccounting }
        worksheet['BF1'] = { t: 'n', f: `SUBTOTAL(9, BF3:BF${rows.length + 3})`, z: formatMoneyAccounting } 
        
        utils.sheet_add_json(worksheet, rows, {
            origin: { r: 1, c: 0 } 
        });

        for (let i = 2; i <= rows.length + 2; i++) {   
            worksheet[`G${i}`].z = formatMoneyAccounting 
            worksheet[`L${i}`].z = formatMoneyAccounting 
            worksheet[`M${i}`].z = formatMoneyAccounting 
            worksheet[`N${i}`].z = formatMoneyAccounting 
            worksheet[`O${i}`].z = formatMoneyAccounting
            worksheet[`P${i}`].z = formatMoneyAccounting 
            worksheet[`Q${i}`].z = formatMoneyAccounting 
            worksheet[`R${i}`].z = formatMoneyAccounting 
            worksheet[`S${i}`].z = formatMoneyAccounting 
            worksheet[`T${i}`].z = formatMoneyAccounting 
            worksheet[`U${i}`].z = formatMoneyAccounting 
            worksheet[`V${i}`].z = formatMoneyAccounting 
            worksheet[`W${i}`].z = formatMoneyAccounting 
            worksheet[`X${i}`].z = formatMoneyAccounting 
            worksheet[`Y${i}`].z = formatMoneyAccounting 
            worksheet[`Z${i}`].z = formatMoneyAccounting 
            worksheet[`AA${i}`].z = formatMoneyAccounting 
            worksheet[`AB${i}`].z = formatMoneyAccounting 
            worksheet[`AC${i}`].z = formatMoneyAccounting 
            worksheet[`AD${i}`].z = formatMoneyAccounting 
            worksheet[`AE${i}`].z = formatMoneyAccounting 
            worksheet[`AF${i}`].z = formatMoneyAccounting 
            worksheet[`AG${i}`].z = formatMoneyAccounting 
            worksheet[`AH${i}`].z = formatMoneyAccounting 
            worksheet[`AI${i}`].z = formatMoneyAccounting 
            worksheet[`AJ${i}`].z = formatMoneyAccounting 
            worksheet[`AK${i}`].z = formatMoneyAccounting 
            worksheet[`AL${i}`].z = formatMoneyAccounting 
            worksheet[`AM${i}`].z = formatMoneyAccounting 
            worksheet[`AN${i}`].z = formatMoneyAccounting 
            worksheet[`AO${i}`].z = formatMoneyAccounting 
            worksheet[`AP${i}`].z = formatMoneyAccounting 
            worksheet[`AQ${i}`].z = formatMoneyAccounting 
            worksheet[`AR${i}`].z = formatMoneyAccounting 
            worksheet[`AS${i}`].z = formatMoneyAccounting 
            worksheet[`AT${i}`].z = formatMoneyAccounting 
            worksheet[`AU${i}`].z = formatMoneyAccounting 
            worksheet[`AW${i}`].z = formatMoneyAccounting 
            worksheet[`AV${i}`].z = formatMoneyAccounting 
            worksheet[`AW${i}`].z = formatMoneyAccounting 
            worksheet[`AX${i}`].z = formatMoneyAccounting 
            worksheet[`AY${i}`].z = formatMoneyAccounting 
            worksheet[`AZ${i}`].z = formatMoneyAccounting 
            worksheet[`BA${i}`].z = formatMoneyAccounting 
            worksheet[`BB${i}`].z = formatMoneyAccounting 
            worksheet[`BC${i}`].z = formatMoneyAccounting 
            worksheet[`BD${i}`].z = formatMoneyAccounting
            worksheet[`BF${i}`].z = formatMoneyAccounting
             
        }

        worksheet["!cols"] =  Array(colluns).fill({ wch: 20 });

        const workbook = utils.book_new()

        utils.book_append_sheet(workbook, worksheet, 'Titulos')

        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear().toString();
        const currentDateFormated =  day + month + year;

        writeFile(workbook, `PosicaoTotal_${currentDateFormated}.xlsx`, {
            compression: true,
        })
    }

    return {
        onDownload,
    }
}
