import { currencyFormat, numberFormat } from "@/utils/stringFormatter"  
import { TypographyMuted, TypographyP, TypographyLead } from "@/components/ui/typography"
import { Wallet, HandshakeIcon, Package, ExternalLink } from 'lucide-react';
import { useState } from "react";
import useDisclosure from "@/hooks/useDisclosure";
import { useUnsettledSecuritiesPositionTotal } from "@/queries/useUnsettledSecuritiesTotalPositionQuery";
import DialogUnsettledSecurities from "../../../DialogUnsettledSecurities";

type informationProps = {
    overdueAmount: number
    amountDueToday: number
    amountToMature: number
    
    totalInPortifolio: number
    overdueAmountAgreement: number
    amountDueTodayAgreement: number
    amountToMatureAgreement: number
    fullyInAgreement: number
    
    overdueAmountpercentageOfRepresentation: number
    amountDueTodaypercentageOfRepresentation: number
    amountToMaturepercentageOfRepresentation: number
    totalInPortifolioOfRepresentation: number
    
    overdueAmountAgreementpercentageOfRepresentation: number
    amountDueTodayAgreementpercentageOfRepresentation: number
    amountToMatureAgreementpercentageOfRepresentation: number
    fullyInAgreementOfRepresentation: number
    
    amountProductTypePA: number
    amountProductTypeAI: number
    amountProductTypePAPercentageOfRepresentation: number
    amountProductTypeAIPercentageOfRepresentation: number
    averageDaysPA: number
}

type Props = {
    information: informationProps
    uf: string[],
    distributor: string
}

function Position3Text({information, distributor, uf} : Props) {
    const [enableStatus, setEnableStatus] = useState(false)
    const [inAgreement, setInAgreement] = useState(false)
    const {
        isOpen,
        onClose,
        onOpen,
    } = useDisclosure()

     
    const { data, isFetching, isLoading, refetch } = useUnsettledSecuritiesPositionTotal({DD_ESTADO: uf, NM_PROPRIETARIO: distributor ,TITULOS_EM_ACORDO: inAgreement,POSICOES:['POSICAO3']},enableStatus)
        
    const handleFetchHistoryPayment = () => { 

        if(enableStatus){
            setEnableStatus(true)
        } else {
            refetch()
        }

        onOpen()
    }

    return (
        <div className="flex-1 rounded-xl bg-muted p-4">
            <TypographyLead className="content-center text-black">Posição 3</TypographyLead>
            <div className="mt-2">
                <div className="flex gap-2 items-center text-blue-600 font-semibold mb-1">
                    <Wallet size={16} />
                    <h4 className="flex items-center gap-2">Carteira <ExternalLink  onClick={() => { setInAgreement(false) ; handleFetchHistoryPayment()}} className="h-5 cursor-pointer hover:opacity-65"/></h4>
                </div>
                <div className="flex flex-col text-gray-600">
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Valor Vencido: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.overdueAmount)} ({numberFormat(information.overdueAmountpercentageOfRepresentation)}%)</TypographyP>
                    </div>
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Valor Vencido Hoje: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.amountDueToday)} ({numberFormat(information.amountDueTodaypercentageOfRepresentation)}%)</TypographyP>
                    </div>
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Valor à Vencer: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.amountToMature)} ({numberFormat(information.amountToMaturepercentageOfRepresentation)}%)</TypographyP>
                    </div> 
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Total em Carteira: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.totalInPortifolio)} ({numberFormat(information.totalInPortifolioOfRepresentation)}%)</TypographyP>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="flex items-center gap-2 text-green-600 font-semibold mb-1">
                    <HandshakeIcon size={16} />
                    <h4 className="flex items-center gap-2">Em Acordo <ExternalLink  onClick={() => { setInAgreement(false) ; handleFetchHistoryPayment()}} className="h-5 cursor-pointer hover:opacity-65"/></h4>
                </div>
                <div className="flex flex-col text-gray-600">
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Valor Vencido: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.overdueAmountAgreement)} ({numberFormat(information.overdueAmountAgreementpercentageOfRepresentation)}%)</TypographyP>
                    </div>
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Valor Vencido Hoje: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.amountDueTodayAgreement)} ({numberFormat(information.amountDueTodayAgreementpercentageOfRepresentation)}%)</TypographyP>
                    </div>
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Valor à Vencer: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.amountToMatureAgreement)} ({numberFormat(information.amountToMatureAgreementpercentageOfRepresentation)}%)</TypographyP>
                    </div> 
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Total em Acordo: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.fullyInAgreement)} ({numberFormat(information.fullyInAgreementOfRepresentation)}%)</TypographyP>
                    </div>
                </div>
            </div>
            <div className="mt-2">
                <div className="flex items-center gap-1 text-purple-600 font-semibold mb-1">
                    <Package size={16} />
                    <h4>Produtos</h4>
                </div>
                <div className="flex flex-col text-gray-600">
                    <div className="flex gap-2">
                        <TypographyMuted className="content-center">Produto Acabado: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.amountProductTypePA)} ({numberFormat(information.amountProductTypePAPercentageOfRepresentation)}%) - {numberFormat(information.averageDaysPA)} Média Dias</TypographyP>
                    </div>  
                    <div className="flex gap-2 mt-1">
                        <TypographyMuted className="content-center">Total Produtos: </TypographyMuted>
                        <TypographyP>{currencyFormat(information.amountProductTypePA)} ({numberFormat(information.amountProductTypePAPercentageOfRepresentation)}%) - {numberFormat(information.averageDaysPA)} Média Dias</TypographyP>
                    </div>
                </div>
            </div>
            <DialogUnsettledSecurities isOpen={isOpen}  onClose={onClose} data={data || []} isFetching={isFetching} isLoading={isLoading}/>
        </div> 
  )
}

export default Position3Text
